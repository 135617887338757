import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import enLocale from 'date-fns/locale/en-US'
import { IDateRangeWrapperProps } from 'types/components/calendarDateRange'

import DateRangePicker from './components/dateRange'
import { CalendarWrapper, Wrapper } from './styles'

const CalendarDateRange: React.FC<IDateRangeWrapperProps> = ({
  toggle,
  closeOnClickOutside,
  wrapperProps,
  ...props
}) => {
  const { opened } = props

  const [locale, setLocale] = useState(enLocale)
  const {
    i18n: { language },
  } = useTranslation()

  const handleToggle = () => {
    if (closeOnClickOutside === false) {
      return
    }

    toggle()
  }

  const handleKeyPress = (event: any) => event?.key === 'Escape' && handleToggle()

  useEffect(() => {
    const importLocaleFile = async () => {
      const { default: localeFile } = await import(`date-fns/locale/${language}`)
      setLocale(localeFile)
    }

    if (locale.code !== language) importLocaleFile()
  }, [language])

  return (
    <Wrapper>
      <CalendarWrapper {...wrapperProps} open={opened} onClose={toggle} onKeyPress={handleKeyPress}>
        <DateRangePicker locale={locale} {...props} />
      </CalendarWrapper>
    </Wrapper>
  )
}

export default CalendarDateRange
