// Filter Menu paths to show based on conditions

// filters the paths array based on a list of conditions and excluded paths to exclude in case that condition is true
/* conditionsList example:
  [
    {condition: userInfo.data.courses == [], excludedPaths: ['/student', '/student/schedule']}
  ]
*/
export const filterPathsBy = (listToFilter, conditionsList) => {
  let pathsToExclude = []

  conditionsList.forEach((condition) => {
    // everytime a condition is true, add to pathsToExclude array the exludedPaths
    if (condition['condition']) pathsToExclude = pathsToExclude.concat(condition['excludedPaths'])
  })

  // returns the list filtered without the objects that contain excluded paths
  return listToFilter.filter((item) => !pathsToExclude.includes(item.path))
}
