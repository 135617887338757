import React from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { Box, Button, Stack, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'

import { downloadFile } from 'common/util/downloadFile'

import { FileCard } from '../FileCard'
import BlockContent from './BlockContent'
import RejectionFiles from './RejectionFiles'
import { UploadMultiFileProps } from './type'

interface IDropZoneStyle extends BoxProps {
  disabled: boolean
}

const DropZoneStyle = styled('div')<IDropZoneStyle>(({ theme, disabled }) => ({
  outline: 'none',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.default,
  border: `1px dashed ${theme.palette.grey[400]}`,
  '&:hover': { opacity: 0.72, cursor: disabled ? 'not-allowed' : 'pointer' },
  ...(disabled && {
    filter: 'grayscale(1)',
    opacity: 0.8,
  }),
}))

export default function UploadMultiFile({
  error,
  files,
  onRemove,
  helperText,
  sx,
  title,
  description,
  onRemoveAll,
  removeAllText,
  disabled = false,
  ...other
}: UploadMultiFileProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    disabled,
    ...other,
  })

  const { t } = useTranslation()

  const removeFile = onRemove || (() => null)

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        disabled={disabled}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
        }}
      >
        <input {...getInputProps()} />

        <BlockContent title={title} description={description} />
      </DropZoneStyle>

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      {/* <MultiFilePreview files={files} showPreview={showPreview} onRemove={onRemove} /> */}

      <Stack spacing={2} sx={{ mt: 2 }}>
        {files?.map((file, index) => (
          <FileCard
            key={index}
            text={typeof file === 'string' ? file : file.name || file.filename || ''}
            onCardContentClick={() => downloadFile(file)}
            onClick={() => removeFile(file)}
            hideDeleteButton={false}
          />
        ))}
      </Stack>

      {files.length > 0 && (
        <Stack direction='row' justifyContent='flex-end' spacing={1.5} mt={2}>
          <Button color='inherit' size='small' onClick={onRemoveAll}>
            {removeAllText || t('common:removeAll')}
          </Button>
        </Stack>
      )}

      {helperText && helperText}
    </Box>
  )
}
