import React from 'react'

const FormationRules = (props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 31 31' {...props}>
      <g className='b' transform='translate(214 -20)'>
        <g transform='translate(-211.571 20)'>
          <path
            className='c'
            d='M7.413,4.927A2.486,2.486,0,1,0,4.927,7.413,2.489,2.489,0,0,0,7.413,4.927Zm-3.762,0A1.275,1.275,0,1,1,4.927,6.2,1.277,1.277,0,0,1,3.651,4.927Zm0,0'
          />
          <path
            className='c'
            d='M21.637,15.864a6.2,6.2,0,0,0-3.688,1.007l-2.171-.483a.605.605,0,1,0-.263,1.182l1.664.371a1.5,1.5,0,0,0-.048.372c0,.823.673,1.525,1.752,1.964l-.969,1a.606.606,0,0,0,.868.844l1.432-1.474a8.556,8.556,0,0,0,1.422.116c2.569,0,4.506-1.052,4.506-2.448S24.206,15.864,21.637,15.864Zm0,3.685c-1.981,0-3.295-.745-3.295-1.237a.515.515,0,0,1,.167-.333.526.526,0,0,0,.053-.053,4.853,4.853,0,0,1,3.075-.851c1.981,0,3.295.745,3.295,1.237S23.618,19.548,21.637,19.548Zm0,0'
          />
          <path
            className='c'
            d='M14.437,26.588l.969-1a.605.605,0,0,0-.868-.844L13.106,26.22a8.556,8.556,0,0,0-1.422-.116c-2.569,0-4.506,1.052-4.506,2.448S9.115,31,11.684,31s4.506-1.052,4.506-2.448C16.189,27.73,15.516,27.027,14.437,26.588Zm-2.754,3.2c-1.981,0-3.295-.745-3.295-1.237s1.314-1.237,3.295-1.237,3.295.745,3.295,1.237S13.664,29.789,11.684,29.789Zm0,0'
          />
          <path
            className='c'
            d='M10.786,16.516a.605.605,0,1,0,.263-1.182l-1.665-.371a1.483,1.483,0,0,0,.048-.372c0-1.135-1.262-1.9-2.708-2.245l2.7-5.411.013-.027A4.927,4.927,0,1,0,0,4.927,4.891,4.891,0,0,0,.415,6.907l.013.027,2.7,5.417C1.682,12.7.421,13.47.421,14.591c0,1.4,1.937,2.448,4.506,2.448a6.2,6.2,0,0,0,3.688-1.006ZM1.211,4.927a3.716,3.716,0,1,1,7.125,1.48L4.927,13.235,1.518,6.407A3.686,3.686,0,0,1,1.211,4.927Zm.421,9.664c0-.318.789-.871,2.057-1.124l.7,1.394a.606.606,0,0,0,1.083,0l.7-1.4c1.283.243,2.055.795,2.055,1.127a.517.517,0,0,1-.168.334A.6.6,0,0,0,8,14.977a4.855,4.855,0,0,1-3.074.851C2.946,15.828,1.632,15.083,1.632,14.591Zm0,0'
          />
          <path className='c' d='M16.66,22.826a.605.605,0,1,0,.605.605A.606.606,0,0,0,16.66,22.826Zm0,0' />
          <path className='c' d='M13.282,17.056a.605.605,0,1,0-.605-.605A.607.607,0,0,0,13.282,17.056Zm0,0' />
        </g>
      </g>
    </svg>
  )
}

export default FormationRules
