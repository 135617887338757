import React from 'react'

import { Box, Typography, Stack } from '@mui/material'

import { UploadIllustration } from 'common/assets/images/illustrations'

interface IBlockContent {
  title?: string
  description?: string | React.ReactNode
}

const BlockContent = ({ title, description }: IBlockContent) => (
  <Stack
    spacing={2}
    alignItems='center'
    justifyContent='center'
    direction={{ xs: 'column', md: 'row' }}
    sx={{ width: 1, textAlign: { xs: 'center' } }}
  >
    <UploadIllustration sx={{ width: 220, minWidth: 156 }} />

    <Box sx={{ p: 3, maxWidth: 512 }}>
      <Typography gutterBottom variant='h5'>
        {title}
      </Typography>
      {typeof description === 'string' ? (
        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
          {description}
        </Typography>
      ) : (
        description
      )}
    </Box>
  </Stack>
)

BlockContent.defaultProps = {
  title: '',
  description: '',
}

export default BlockContent
