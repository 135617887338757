import i18n from 'translations'

export const generalLinks = [
  {
    name: i18n.t('students:units'),
    path: '/admin/general-parameters/units',
  },
  {
    name: i18n.t('courses:name'),
    path: '/admin/general-parameters/courses',
  },
  {
    name: i18n.t('courses:modalities'),
    path: '/admin/general-parameters/modalities',
  },
  {
    name: i18n.t('institution-logo:name'),
    path: '/admin/general-parameters/institution-logo',
  },
  {
    name: 'Cor Institucional',
    path: '/admin/general-parameters/institution-colors',
  },
  {
    name: i18n.t('functional-areas:descriptionAreas'),
    path: '/admin/general-parameters/area-descriptions',
  },
  {
    name: i18n.t('functional-areas:eadTool'),
    path: '/admin/general-parameters/ead-tools',
  },
  {
    name: 'Ferramentas Externas',
    path: '/admin/general-parameters/external-tools',
  },
  {
    name: i18n.t('profiles:singular-name'),
    path: '/admin/general-parameters/coordinator-profiles',
  },
  {
    name: i18n.t('profiles:teacher-singular'),
    path: '/admin/general-parameters/teacher-profiles',
  },
  {
    name: i18n.t('functional-areas:exerciseYear'),
    path: '/admin/general-parameters/year-exercises',
  },
  {
    name: i18n.t('blox-details:auditLogs'),
    path: '/admin/general-parameters/audit-logs',
  },
  {
    name: i18n.t('email-Notifications:name'),
    path: '/admin/general-parameters/email-Notifications',
  },
]
