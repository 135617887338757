import React from 'react'
import { useFormContext } from 'react-hook-form'

import { TextField, TextFieldProps } from '@mui/material'

import { ControllerPlus as Controller } from 'common/components/hook-form'

import type { TransformType } from './ControllerPlus'

type IProps = React.PropsWithChildren<{
  name: string
  transform?: TransformType<string | undefined, React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, any>
}>

type Props = IProps & TextFieldProps

export default function RHFSelect({ name, transform, children, ...other }: Props) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      transform={transform}
      render={({ field: { onChange, value, ...fieldProps }, transform, fieldState: { error } }) => (
        <TextField
          {...fieldProps}
          size='small'
          select
          fullWidth
          error={!!error}
          helperText={error?.message}
          onChange={(event) => onChange(transform?.output ? transform.output(event) : event)}
          value={transform?.input ? transform.input(value) : value}
          {...other}
        >
          {children}
        </TextField>
      )}
    />
  )
}
