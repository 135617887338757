import React, { useCallback, useEffect, useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import InfoIcon from '@mui/icons-material/Info'
import {
  Autocomplete,
  AutocompleteProps as MaterialAutoCompleteProps,
  ButtonGroupProps,
  CircularProgress,
  MenuItem,
  SelectProps,
  TextField,
  TextFieldProps,
  Tooltip,
  TooltipProps,
} from '@mui/material'

import { Select, ButtonGroup } from './styles'

export type RHFBehavioralSelectValue<TValue, IsMultiple extends boolean = false> = IsMultiple extends true
  ? { behavior: string; value: TValue[] }
  : { behavior: string; value: TValue }

type SelectOnChange = Required<Pick<SelectProps<string>, 'onChange'>>['onChange']
type AutocompleteOnChange = Required<Pick<AutocompleteProps, 'onChange'>>['onChange']

type AutocompleteProps = MaterialAutoCompleteProps<any, boolean | undefined, boolean | undefined, boolean | undefined>
type AutocompleteOmittedProps = Omit<
  MaterialAutoCompleteProps<any, boolean | undefined, boolean | undefined, boolean | undefined>,
  'renderInput' | 'onChange' | 'options'
>

type BehaviorsList =
  | { behaviors: { value: string; label: string; helperText?: never }[]; useHelp?: never }
  | { behaviors: { value: string; label: string; helperText: string }[]; useHelp: true }

type CustomComponent<C extends React.ElementType> =
  | {
      options?: never
      autocompleteProps?: never
      inputComponent: C
      inputProps?: React.ComponentProps<C>
      transformValue?: (value: any) => any
    }
  | {
      options: Required<AutocompleteProps['options']>
      autocompleteProps: AutocompleteOmittedProps & { textFieldProps?: TextFieldProps }
      inputComponent?: never
      inputProps?: never
      transformValue?: never
    }

type BehavioralSelectProps<C extends React.ElementType> = BehaviorsList &
  CustomComponent<C> & {
    name: string
    disabled?: boolean
    isLoading?: boolean
    defaultBehavior?: string
    defaultValue?: any | any[]
    tooltipProps?: TooltipProps
    buttonGroupProps?: ButtonGroupProps
  }

type Props<C extends React.ElementType> = BehavioralSelectProps<C> & Omit<SelectProps<string>, 'inputComponent'>

const BehavioralSelect = <C extends React.ElementType>({
  name,
  behaviors,
  options,
  disabled,
  inputComponent: InputComponent,
  inputProps,
  transformValue,
  isLoading,
  useHelp,
  defaultValue,
  defaultBehavior,
  tooltipProps,
  autocompleteProps: { textFieldProps, ...autocompleteProps } = {},
  ...other
}: Props<C>) => {
  const [selectedBehavior, setSelectedBehavior] = useState<string>(defaultBehavior ?? behaviors[0].value)
  const { control, setValue, watch } = useFormContext()

  const onChangeBehavior = useCallback<SelectOnChange>(
    (event, _node) => {
      const newBehaviour = event.target.value

      setSelectedBehavior(newBehaviour)
    },
    [setSelectedBehavior],
  )

  const handleChange = useCallback<AutocompleteOnChange>(
    (_event, newValue) => {
      if (newValue) {
        setValue(name, { behavior: selectedBehavior, value: newValue })
      }
    },
    [setValue, selectedBehavior],
  )

  const handleChangeCustom = useCallback(
    (value: any) => {
      if (!transformValue) {
        throw new Error('transformValue is required when using a custom input component')
      }

      setValue(name, { behavior: selectedBehavior, value: transformValue(value) })
    },
    [setValue, selectedBehavior],
  )

  useEffect(() => {
    const abortController = new AbortController()
    const { value: AutoCompleteValue, behavior: previousBehavior } = watch(name) ?? {}

    if (!AutoCompleteValue || !previousBehavior) return

    if (previousBehavior !== selectedBehavior) {
      setValue(name, { behavior: selectedBehavior, value: AutoCompleteValue })
    }

    return () => {
      abortController.abort()
    }
  }, [selectedBehavior])

  return (
    <Controller
      name={name}
      control={control}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      render={({ field: { onChange, value, ...fieldProps }, fieldState: { error } }) => (
        <ButtonGroup fullWidth variant='outlined' color='primary' disabled={disabled}>
          <Select
            variant='outlined'
            onChange={onChangeBehavior}
            value={selectedBehavior}
            disabled={disabled}
            {...other}
          >
            {behaviors.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
          {InputComponent ? (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            <InputComponent
              fullWidth
              disabled={disabled}
              options={options}
              value={value?.value}
              {...autocompleteProps}
              {...fieldProps}
              onFinish={handleChangeCustom}
              onChange={handleChangeCustom}
              componentProps={{
                ...fieldProps,
                onFinish: handleChangeCustom,
                onChange: handleChangeCustom,
                ...inputProps?.componentProps,
              }}
              {...inputProps}
            />
          ) : (
            <Autocomplete
              fullWidth
              freeSolo
              disabled={disabled}
              value={value?.value}
              {...autocompleteProps}
              {...fieldProps}
              options={options as AutocompleteProps['options']}
              renderInput={(params) => (
                <TextField
                  {...textFieldProps}
                  {...params}
                  size='small'
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                        {useHelp && (
                          <Tooltip
                            arrow
                            title={behaviors.find(({ value }) => value === selectedBehavior)?.helperText}
                            {...tooltipProps}
                          >
                            <InfoIcon sx={({ palette }) => ({ color: palette.action.active })} />
                          </Tooltip>
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                    sx: {
                      boxSizing: 'border-box',
                      border: 'unset',
                      '& input': {
                        border: '0 !important',
                      },
                      '&:before': { borderBottom: 'unset !important' },
                      '&:after': { borderBottom: 'unset' },
                      '&:hover:not(.Mui-disabled, .Mui-error):before': { borderBottom: 'unset' },
                    },
                  }}
                />
              )}
              onChange={handleChange}
            />
          )}
          {error && <span>{error.message}</span>}
        </ButtonGroup>
      )}
      defaultValue={defaultValue}
    />
  )
}

export default BehavioralSelect
