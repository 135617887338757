import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import MenuIcon from '@mui/icons-material/Menu'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import styled from 'styled-components'

import { useMobileHook } from 'common/hooks/browser'
import { useApi } from 'common/hooks/useApi'

import { TokensEndPoint } from 'routes/locations/endpoints/customLinks'

const LinkArray = ({ links = [], openMenu, setOpenMenu, isMobile }) => {
  const [, setToken] = useState('')
  const [getToken] = useApi(TokensEndPoint, 'POST')

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await getToken()
        setToken(response.token)
      } catch (error) {
        console.log(error)
      }
    }

    loadData()
  }, [])

  const linksArray = links.map((item, idx) => {
    const Icon = item.icon

    const active = item.actives
      ? item.actives.some((item) => location.pathname.startsWith(item))
      : location.pathname.startsWith(item.path)

    return (
      <Item
        openMenu={openMenu}
        isMobile={isMobile}
        onClick={() => setOpenMenu(false)}
        active={active}
        key={idx}
        to={item.path || '/'}
      >
        {item.icon && <Icon />}
        <Text>{item.name}</Text>
      </Item>
    )
  })

  return linksArray
}

const AdminSidebar = (props) => {
  const [openMenu, setOpenMenu] = useState(false)
  const isMobile = useMobileHook()

  return (
    <Container>
      <Base isMobile={isMobile} openMenu={openMenu}>
        {isMobile ? (
          <CustomItem onClick={() => setOpenMenu(!openMenu)}>{openMenu ? <MenuOpenIcon /> : <MenuIcon />}</CustomItem>
        ) : (
          ''
        )}
        <MobileContainer isMobile={isMobile} openMenu={openMenu}>
          <LinkArray isMobile={isMobile} setOpenMenu={setOpenMenu} openMenu={openMenu} {...props} />
        </MobileContainer>
      </Base>
    </Container>
  )
}

export const ResponsiveContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 120px 0 0;
  flex-direction: column;
  @media screen and (max-width: 991px) {
    & > div {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
  @media screen and (min-width: 992px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: ${({ justifyContent = 'flex-start' }) => justifyContent};
    margin: 80px 0 0;
  }
`

const Container = styled.div`
  position: fixed;
  top: 245px;
  left: 10px;
  max-width: 300px;
  padding: 0 0 20px;
  z-index: 1;
  @media screen and (max-width: 849px) {
    top: 425px;
  }
  @media screen and (min-width: 992px) {
    position: -webkit-sticky;
    top: 50px;
    position: sticky;
    padding: 0 0 150px 68px;
  }
`
const MobileContainer = styled.div`
  ${({ isMobile }) => (isMobile ? 'overflow: scroll' : '')};
  display: flex;
  flex-direction: column;
  max-height: ${({ openMenu, isMobile }) => (isMobile ? (openMenu ? '550px' : '0px') : '100%')};
  height: 100%;
  width: 100%;
  transition: all 0.7s ease-in-out;

  @media screen and (max-width: 992px) {
    display: ${({ openMenu }) => (openMenu ? 'flex' : 'none')};
  }
`

const Base = styled.div`
  display: flex;
  flex-direction: row;
  display: flex;
  align-items: flex-start;
  height: 100%;
  max-height: ${({ openMenu, isMobile }) => (isMobile ? (openMenu ? '1500px' : '72px') : '100%')};
  overflow: hidden;
  transition: ${({ openMenu }) => (openMenu ? 'all 1s ease-in-out' : 'all 0.5s ease-in-out')};
  @media screen and (min-width: 992px) {
    height: auto;
  }
`

const Item = styled(Link)`
  box-sizing: border-box;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 22px 26px 24px;
  border: 1px solid #e9e9f0;
  cursor: pointer;
  opacity: ${({ openMenu, isMobile }) => (isMobile ? (openMenu ? '1' : '0') : '1')};
  transition: all 0.7s ease-in-out;
  & {
    background-color: ${({ active }) => (active ? '#ededed' : '#fff')};
  }
  path {
    fill: #39414a;
  }
  &:not(:first-child) {
    border-top: none;
  }
  &:hover {
    background-color: #ededed;
  }
  &:hover p {
    color: #2795f4;
  }
  &:hover path {
    fill: #2795f4;
  }
  &:hover div {
    background-color: #2795f4;
  }
`
const CustomItem = styled.button`
  box-sizing: border-box;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  padding: 22px 26px 24px;
  border: 1px solid #e9e9f0;
  cursor: pointer;
  color: #fff;
  background-color: #4589fa;
  transition: all 0.5s ease-in-out;
`

const Text = styled.p`
  padding: 0;
  margin: 0 0 0 26px;
  color: #39414a;
  font-size: 14px;
  line-height: 20px;
`

export default AdminSidebar
