import React from 'react'

import { Paper, Grid, Typography } from '@mui/material'
import { getDate, isSameMonth, isToday, isWithinInterval } from 'date-fns'
import { IViewMonthProps, NavigationAction } from 'types/components/calendarDateRange'

import { chunks, getDaysInMonth, inDateRange, isEndOfRange, isRangeSameDay, isStartOfRange } from '../utils'
import Day from './day'
import Header from './header'

const Month: React.FC<IViewMonthProps> = ({
  helpers,
  handlers,
  dateRange,
  value: date,
  setValue: setDate,
  minDate,
  maxDate,
  navState,
  locale,
}) => {
  const [back, forward] = navState,
    weekStartsOn = locale?.options?.weekStartsOn || 0,
    WEEK_DAYS =
      typeof locale !== 'undefined'
        ? [...Array(7).keys()].map((day) =>
            locale.localize?.day((day + weekStartsOn) % 7, { width: 'short', context: 'standalone' }),
          )
        : ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

  return (
    <Paper square elevation={0} sx={{ width: 290 }}>
      <Grid container>
        <Header
          date={date}
          setDate={setDate}
          nextDisabled={!forward}
          prevDisabled={!back}
          onClickPrevious={() => handlers.onMonthNavigate(NavigationAction.Previous)}
          onClickNext={() => handlers.onMonthNavigate(NavigationAction.Next)}
          locale={locale}
        />
        <Grid
          item
          container
          direction='row'
          justifyContent='space-between'
          sx={{
            marginTop: '10px',
            paddingLeft: '30px',
            paddingRight: '30px',
          }}
        >
          {WEEK_DAYS.map((day, index) => (
            <Typography color='textSecondary' key={index} variant='caption'>
              {day}
            </Typography>
          ))}
        </Grid>

        <Grid
          item
          container
          direction='column'
          justifyContent='space-between'
          sx={{
            paddingLeft: '15px',
            paddingRight: '15px',
            marginTop: '15px',
            marginBottom: '20px',
          }}
        >
          {chunks(getDaysInMonth(date, locale), 7).map((week, idx) => (
            <Grid key={idx} container direction='row' justifyContent='center'>
              {week.map((day) => {
                const isStart = isStartOfRange(dateRange, day),
                  isEnd = isEndOfRange(dateRange, day),
                  isRangeOneDay = isRangeSameDay(dateRange),
                  highlighted = inDateRange(dateRange, day) || helpers.inHoverRange(day)

                return (
                  <Day
                    key={day.toISOString()}
                    filled={isStart || isEnd}
                    outlined={isToday(day)}
                    highlighted={highlighted && !isRangeOneDay}
                    disabled={!isSameMonth(date, day) || !isWithinInterval(day, { start: minDate, end: maxDate })}
                    startOfRange={isStart && !isRangeOneDay}
                    endOfRange={isEnd && !isRangeOneDay}
                    onClick={() => handlers.onDayClick(day)}
                    onHover={() => handlers.onDayHover(day)}
                    value={getDate(day)}
                  />
                )
              })}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Month
