import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, FormControl, FormGroup, Grid, Typography } from '@mui/material'

import { RHFCheckbox, RHFTextField } from 'common/components/hook-form'

import modalHooks from '../../function'
import { Label } from './styles'

export const InformationSchool = (): JSX.Element => {
  const { t } = useTranslation()
  const { disableFields, disableField } = modalHooks()

  return (
    <Box>
      <Box mb={5}>
        <Typography variant='h6'>{t('menu:informationComplement')}</Typography>
        <Typography variant='body2' sx={{ fontSize: '0.8rem' }}>
          {t('menu:createRegistration')}
        </Typography>
      </Box>
      <Grid container sx={{ pr: 2 }}>
        <Grid item xs={12} mb={1}>
          <Typography variant='body2' sx={{ fontSize: '0.8rem' }}>
            {t('menu:haveAcessibleComputerLab')}
          </Typography>
          <Grid item xs={12} mt={1} mb={1}>
            <RHFTextField name='have_acessible_computer_lab' type='text' variant='outlined' label />
          </Grid>
        </Grid>

        <Grid item xs={12} mt={1} mb={1}>
          <FormControl>
            <Label>{t('menu:generalBuildingConditionsGood')}</Label>
            <FormGroup aria-label='position' row>
              <Box
                onChange={() =>
                  disableField(['general_building_conditions_rules', 'general_building_conditions_terrible'])
                }
              >
                <RHFCheckbox
                  name='general_building_conditions_good'
                  label={t('menu:good')}
                  labelPlacement='start'
                  value='good'
                  disabled={disableFields.general_building_conditions_good}
                />
              </Box>
              <Box
                onChange={() =>
                  disableField(['general_building_conditions_good', 'general_building_conditions_terrible'])
                }
              >
                <RHFCheckbox
                  name='general_building_conditions_rules'
                  label={t('functional-areas:regular')}
                  labelPlacement='start'
                  value='rules'
                  disabled={disableFields.general_building_conditions_rules}
                />
              </Box>
              <Box
                onChange={() => disableField(['general_building_conditions_good', 'general_building_conditions_rules'])}
              >
                <RHFCheckbox
                  name='general_building_conditions_terrible'
                  label={t('menu:terrible')}
                  labelPlacement='start'
                  value='terrible'
                  disabled={disableFields.general_building_conditions_terrible}
                />
              </Box>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} mt={1} mb={1}>
          <Typography variant='body2' sx={{ fontSize: '0.8rem' }}>
            {t('menu:rooms')}
          </Typography>
          <Grid item xs={12} mt={1} mb={1}>
            <RHFTextField name='rooms' type='text' variant='outlined' label />
          </Grid>
        </Grid>
        <Grid item xs={12} mt={1} mb={1}>
          <FormControl>
            <Label>{t('menu:location')}</Label>
            <FormGroup aria-label='position' row>
              <Box onChange={() => disableField(['location_rural'])}>
                <RHFCheckbox
                  name='location_urban'
                  label={t('menu:urban')}
                  labelPlacement='start'
                  value='urban'
                  disabled={disableFields.location_urban}
                />
              </Box>
              <Box onChange={() => disableField(['location_urban'])}>
                <RHFCheckbox
                  name='location_rural'
                  label={t('menu:rural')}
                  labelPlacement='start'
                  value='rural'
                  disabled={disableFields.location_rural}
                />
              </Box>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} mt={1} mb={1}>
          <FormControl>
            <Label>{t('menu:type')}</Label>
            <FormGroup aria-label='position' row>
              <Box onChange={() => disableField(['type_municipal', 'type_state'])}>
                <RHFCheckbox
                  name='type_private'
                  label={t('menu:private')}
                  labelPlacement='start'
                  value='private'
                  disabled={disableFields.type_private}
                />
              </Box>
              <Box onChange={() => disableField(['type_private', 'type_state'])}>
                <RHFCheckbox
                  name='type_municipal'
                  label={t('menu:municipal')}
                  labelPlacement='start'
                  value='municipal'
                  disabled={disableFields.type_municipal}
                />
              </Box>
              <Box onChange={() => disableField(['type_private', 'type_municipal'])}>
                <RHFCheckbox
                  name='type_state'
                  label={t('menu:state')}
                  labelPlacement='start'
                  value='state'
                  disabled={disableFields.type_state}
                />
              </Box>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} mt={1} mb={1}>
          <FormControl>
            <Label>{t('menu:period')}</Label>
            <FormGroup aria-label='position' row>
              <Box onChange={() => disableField(['period_evening', 'period_full'])}>
                <RHFCheckbox
                  name='period_morning'
                  label={t('menu:morning')}
                  labelPlacement='start'
                  value='morning'
                  disabled={disableFields.period_morning}
                />
              </Box>
              <Box onChange={() => disableField(['period_morning', 'period_full'])}>
                <RHFCheckbox
                  name='period_evening'
                  label={t('menu:evening')}
                  labelPlacement='start'
                  value='evening'
                  disabled={disableFields.period_evening}
                />
              </Box>
              <Box onChange={() => disableField(['period_morning', 'period_evening'])}>
                <RHFCheckbox
                  name='period_full'
                  label={t('menu:full')}
                  labelPlacement='start'
                  value='full'
                  disabled={disableFields.period_full}
                />
              </Box>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} mt={1} mb={1}>
          <FormControl>
            <Label>{t('menu:haveApm')}</Label>
            <FormGroup aria-label='position' row>
              <Box onChange={() => disableField(['have_apm_inactive', 'have_apm_no'])}>
                <RHFCheckbox
                  name='have_apm_yes'
                  label={t('menu:active')}
                  labelPlacement='start'
                  value='yes'
                  disabled={disableFields.have_apm_yes}
                />
              </Box>
              <Box onChange={() => disableField(['have_apm_yes', 'have_apm_no'])}>
                <RHFCheckbox
                  name='have_apm_inactive'
                  label={t('menu:inactive')}
                  labelPlacement='start'
                  value='inactive'
                  disabled={disableFields.have_apm_inactive}
                />
              </Box>
              <Box onChange={() => disableField(['have_apm_inactive', 'have_apm_yes'])}>
                <RHFCheckbox
                  name='have_apm_no'
                  label={t('menu:no')}
                  labelPlacement='start'
                  value='no'
                  disabled={disableFields.have_apm_no}
                />
              </Box>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} mt={1} mb={1}>
          <FormControl>
            <Label>{t('menu:haveSchoolBoard')}</Label>
            <FormGroup aria-label='position' row>
              <Box onChange={() => disableField(['have_school_board_inactive', 'have_school_board_no'])}>
                <RHFCheckbox
                  name='have_school_board_yes'
                  label={t('menu:active')}
                  labelPlacement='start'
                  value='yes'
                  disabled={disableFields.have_school_board_yes}
                />
              </Box>
              <Box onChange={() => disableField(['have_school_board_yes', 'have_school_board_no'])}>
                <RHFCheckbox
                  name='have_school_board_inactive'
                  label={t('menu:inactive')}
                  labelPlacement='start'
                  value='inactive'
                  disabled={disableFields.have_school_board_inactive}
                />
              </Box>
              <Box onChange={() => disableField(['have_school_board_inactive', 'have_school_board_yes'])}>
                <RHFCheckbox
                  name='have_school_board_no'
                  label={t('menu:no')}
                  labelPlacement='start'
                  value='no'
                  disabled={disableFields.have_school_board_no}
                />
              </Box>
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )
}
