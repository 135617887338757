import React, { ReactNode, useRef, useCallback, useMemo } from 'react'
import ReactQuill, { ReactQuillProps } from 'react-quill'

import { Box, BoxProps, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'

import { useDisclose } from 'common/hooks/useDisclose'
import { FormatHTMLStringIndentation } from 'common/util/FormatHTMLStringIndentation'

import EditorToolbar, { formats } from './EditorToolbar'

const RootStyle = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${theme.palette.grey[400]}`,
  '& .ql-container.ql-snow': {
    borderColor: 'transparent',
    ...theme.typography.body1,
    fontFamily: theme.typography.fontFamily,
  },
  '& .ql-editor': {
    minHeight: 200,
    maxHeight: 640,
    '&.ql-blank::before': {
      fontStyle: 'normal',
      color: theme.palette.text.disabled,
    },
    '& pre.ql-syntax': {
      ...theme.typography.body2,
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.grey[900],
    },
  },
}))

export interface Props extends ReactQuillProps {
  id?: string
  error?: boolean
  simple?: boolean
  helperText?: ReactNode
  endAdornment?: React.ReactNode
  sx?: BoxProps['sx']
  // eslint-disable-next-line no-unused-vars
  onInputChange?: (arg: string) => void
  inputProps?: object
}

export default function Editor({
  id = 'minimal-quill',
  error,
  value,
  onChange,
  onInputChange,
  simple = false,
  helperText,
  endAdornment,
  sx,
  ...other
}: Props) {
  const htmlEditorRef = useRef<HTMLInputElement>(null)
  const { isOpen, onToggle, onClose } = useDisclose()

  const code = useMemo(() => isOpen && FormatHTMLStringIndentation(value as string), [value, isOpen])

  const onChangeHtml = onInputChange || (() => null)

  const handleInputChange = useCallback(
    (newValue: string) => {
      onChangeHtml(newValue)
      onClose()
    },
    [onChangeHtml, onClose],
  )

  const modules = {
    toolbar: {
      container: `#${id}`,
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    clipboard: {
      matchVisual: false,
    },
  }

  return (
    <>
      <RootStyle
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
          }),
          ...sx,
        }}
      >
        <EditorToolbar id={id} isSimple={simple} onEditHtml={onToggle} />
        <ReactQuill value={value} onChange={onChange} modules={modules} formats={formats} {...other} />
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>{endAdornment && endAdornment}</Box>
      </RootStyle>

      <Dialog maxWidth='sm' fullWidth open={isOpen} onClose={onClose}>
        <DialogTitle sx={{ opacity: 0.6, letterSpacing: '0.08rem' }}>Editar conteúdo HTML</DialogTitle>
        <DialogContent>
          <TextField
            inputRef={htmlEditorRef}
            variant='outlined'
            fullWidth
            multiline
            rows={6}
            defaultValue={code}
            sx={{ '& .MuiOutlinedInput-notchedOutline': { top: 0, padding: 0, legend: { display: 'none' } } }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>cancelar</Button>
          <Button onClick={() => handleInputChange(htmlEditorRef.current?.value as string)}>editar</Button>
        </DialogActions>
      </Dialog>

      {helperText && helperText}
    </>
  )
}
