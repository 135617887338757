import { useState, useEffect } from 'react'

import { isMobile as isMobileHelper, isBrowser } from '../util/browser'

export const useMobileHook = (size = 992) => {
  const [isMobile, setIsMobile] = useState(isMobileHelper(size))

  useEffect(() => {
    if (!isBrowser()) {
      return
    }

    const resizeFunction = () => setIsMobile(isMobileHelper(size))

    window.addEventListener('resize', resizeFunction)

    return () => {
      window.removeEventListener('resize', resizeFunction)
    }
  }, [])

  return isMobile
}
