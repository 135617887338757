import React from 'react'
import { Container } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import styled from 'styled-components'

import NavbarLink from './links/NavbarLink'

const AdminNavbar = () => {
  const { t } = useTranslation()

  const pedagogicalParameters = [
    {
      name: `${t('knowledge-areas:name')}`,
      links: [
        {
          name: `${t('button:view')}`,
          path: '/admin/knowledge-areas',
        },
        {
          name: `${t('button:create')}`,
          path: '/admin/knowledge-areas/create',
        },
        {
          name: `${t('button:colors')}`,
          path: '/admin/knowledge-areas/colors',
        },
      ],
    },
    {
      name: `${t('functional-areas:name')}`,
      links: [
        {
          name: `${t('button:view')}`,
          path: '/admin/functional-areas',
        },
        {
          name: `${t('button:create')}`,
          path: '/admin/functional-areas/create',
        },
      ],
    },
    {
      name: `${t('competences:name')}`,
      links: [
        {
          name: `${t('button:view')}`,
          path: '/admin/competences',
        },
        {
          name: `${t('button:create')}`,
          path: '/admin/competences/create',
        },
      ],
    },
    {
      name: `${t('methodologies:name')}`,
      links: [
        {
          name: `${t('button:view')}`,
          path: '/admin/methodologies',
        },
        {
          name: `${t('button:create')}`,
          path: '/admin/methodologies/create',
        },
      ],
    },
    {
      name: `${t('bibliography-types:name')}`,
      links: [
        {
          name: `${t('button:view')}`,
          path: '/admin/bibliography-types',
        },
        {
          name: `${t('button:create')}`,
          path: '/admin/bibliography-types/create',
        },
      ],
    },
    {
      name: `${t('blox-profiles:name')}`,
      links: [
        {
          name: `${t('button:view')}`,
          path: '/admin/blox-profiles',
        },
        {
          name: `${t('button:create')}`,
          path: '/admin/blox-profiles/create',
        },
      ],
    },
    {
      name: 'Templates',
      links: [
        {
          name: 'Visualizar',
          path: '/admin/templates',
        },
      ],
    },
    {
      name: `${t('teaching-Approach:name')}`,
      links: [
        {
          name: `${t('button:view')}`,
          path: '/admin/teaching-Approach',
        },
        {
          name: `${t('button:create')}`,
          path: '/admin/teaching-Approach/create',
        },
      ],
    },
    //{
    //  name: `${t('equivalencies:name')}`,
    //  links: [
    //    {
    //      name: `${t('equivalencies:name')}`,
    //      path: '/admin/equivalencies'
    //    }
    //  ]
    //}
  ]

  const generalParameters = [
    {
      name: `${t('units:name')}`,
      links: [
        {
          name: `${t('button:view')}`,
          path: '/admin/units',
        },
        {
          name: `${t('button:create')}`,
          path: '/admin/units/create',
        },
      ],
    },
    {
      name: `${t('courses:name')}`,
      links: [
        {
          name: `${t('button:view')}`,
          path: '/admin/courses',
        },
      ],
    },
    //{
    //  name: `${t('education-levels:name')}`,
    //  links: [
    //    {
    //      name: `${t('button:view')}`,
    //      path: '/admin/education-levels'
    //    },
    //    {
    //      name: `${t('button:create')}`,
    //      path: '/admin/education-levels/create'
    //    }
    //  ]
    //},
    {
      name: `${t('modalities:name')}`,
      links: [
        {
          name: `${t('button:view')}`,
          path: '/admin/modalities',
        },
        {
          name: `${t('button:create')}`,
          path: '/admin/modalities/create',
        },
      ],
    },
    {
      name: `${t('institution-logo:name')}`,
      links: [
        {
          name: `${t('institution-logo:name')}`,
          path: '/admin/institution-logo',
        },
      ],
    },
    {
      name: `${t('institution-color:institution-color')}`,
      links: [
        {
          name: `${t('institution-color:institution-color')}`,
          path: '/admin/institution-colors',
        },
      ],
    },
    {
      name: `${t('area-descriptions:name')}`,
      links: [
        {
          name: `${t('button:view')}`,
          path: '/admin/area-descriptions',
        },
      ],
    },
    {
      name: `${t('custom-links:name')}`,
      links: [
        {
          name: `${t('button:view')}`,
          path: '/admin/custom-links',
        },
        {
          name: `${t('button:create')}`,
          path: '/admin/custom-links/create',
        },
      ],
    },
    {
      name: 'Ferramentas EAD',
      links: [
        {
          name: 'Editar',
          path: '/admin/ead-tools',
        },
      ],
    },
    {
      name: `${t('profiles:name')}`,
      links: [
        {
          name: `${t('button:view')}`,
          path: '/admin/coordinator-profiles',
        },
        {
          name: `${t('button:create')}`,
          path: '/admin/coordinator-profiles/create',
        },
      ],
    },
    {
      name: `${t('profiles:teacher')}`,
      links: [
        {
          name: `${t('button:view')}`,
          path: '/admin/teacher-profiles',
        },
        {
          name: `${t('button:create')}`,
          path: '/admin/teacher-profiles/create',
        },
      ],
    },
    {
      name: t('year-exercises:name'),
      links: [
        {
          name: t('button:view'),
          path: '/admin/year-exercises',
        },
        {
          name: t('button:create'),
          path: '/admin/year-exercises/create',
        },
      ],
    },
    {
      name: `${t('email-Notifications:name')}`,
      links: [
        {
          name: `${t('button:view')}`,
          path: '/admin/email-Notifications',
        },
        {
          name: t('button:create'),
          path: '/admin/email-Notifications/create',
        },
      ],
    },
  ]

  return (
    <>
      <CustomAppBar position='static'>
        <Toolbar>
          <Container>
            <NavbarLink type='button' text={t('dashboard:name')} path='/admin' />
            <NavbarLink type='button' text={t('users:name')} path='/admin/users' />
            <NavbarLink type='list' text={t('menu:pedagogical-parameters')} links={pedagogicalParameters} />
            <NavbarLink type='list' text={t('menu:general-parameters')} links={generalParameters} />
          </Container>
        </Toolbar>
      </CustomAppBar>
    </>
  )
}

export default withRouter(AdminNavbar)

const CustomAppBar = styled(AppBar)`
  background-color: transparent !important;
  box-shadow: none !important;
`
