import React, { ReactNode } from 'react'
import { FormProvider as Form, UseFormReturn } from 'react-hook-form'

type Props = {
  children: ReactNode
  methods: UseFormReturn<any>
  onSubmit?: VoidFunction
  ref?: React.LegacyRef<HTMLFormElement>
} & React.FormHTMLAttributes<HTMLFormElement>

export default function FormProvider({ children, onSubmit, methods, ref, ...formProps }: Props) {
  return (
    <Form {...methods}>
      <form onSubmit={onSubmit} ref={ref} {...formProps}>
        {children}
      </form>
    </Form>
  )
}
