import React from 'react'
import { Container } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'

import { AppBar, Toolbar } from '@mui/material'
import styled from 'styled-components'

import { useInfo } from 'common/hooks/useInfo'

import NavbarLink from './links/NavbarLink'

export const studentLinks = (t) => [
  {
    text: t('dashboard:name'),
    path: '/student',
  },
  {
    text: t('blox-selection:name'),
    path: '/student/blox-selection',
  },
  {
    text: t('blox-history:name'),
    path: '/student/history',
  },
]
const StudentNavbar = () => {
  const { t } = useTranslation()
  const [userInfo] = useInfo()
  const customLinks = userInfo.data.custom_links

  const links = React.useMemo(() => studentLinks(t), [])
  return (
    <CustomAppBar position='static'>
      <Toolbar>
        <Container>
          {links.map((link, index) => (
            <NavbarLink type='button' text={link.text} path={link.path} key={index} />
          ))}
          {customLinks &&
            customLinks.length > 0 &&
            customLinks.map((customLink) => (
              <NavbarLink
                type='button'
                key={customLink.id}
                text={customLink.title}
                path={customLink.url}
                tooltipText={customLink.description}
                isExternalPath={customLink.is_external}
              />
            ))}
        </Container>
      </Toolbar>
    </CustomAppBar>
  )
}

export default withRouter(StudentNavbar)

const CustomAppBar = styled(AppBar)`
  background-color: transparent !important;
  box-shadow: none !important;
`
