import React, { useState, useMemo } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import ClearIcon from '@mui/icons-material/Clear'
import { IconButton, Tooltip } from '@mui/material'
import { TextField, TextFieldProps } from '@mui/material'
import {
  IDateRangeWrapperProps as ICalendarDateRangeProps,
  IDateRange as IDateRangeValue,
} from 'types/components/calendarDateRange'

import { useDisclose } from 'common/hooks/useDisclose'

import CalendarDateRange from '../CalendarRange'

interface IProps extends Omit<ICalendarDateRangeProps, 'opened' | 'toggle' | 'onChange'> {
  name: string
  format?: Intl.DateTimeFormatOptions
  fieldProps?: TextFieldProps
  withClearButton?: boolean
  transformValue?: (value: IDateRangeValue) => any
}

export default function RHFDateRangePicker({
  name,
  format,
  fieldProps,
  transformValue,
  withClearButton,
  ...other
}: IProps) {
  const {
      t,
      i18n: { language },
    } = useTranslation(),
    { control, setValue, watch } = useFormContext(),
    { isOpen: openedCalendar, onToggle: toggleCalendar, onOpen: openCalendar } = useDisclose(),
    [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null),
    rangeDate = watch(name) as IDateRangeValue

  const inputValue = useMemo(() => {
    if (!rangeDate) return ''
    const { start_at, end_at } = rangeDate

    const startDate = new Intl.DateTimeFormat(language, format).format(start_at),
      endDate = new Intl.DateTimeFormat(language, format).format(end_at)

    return t('group-activities:danteRangeTemplateValue', { startDate, endDate })
  }, [rangeDate, language, format])

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
      openCalendar()
    },
    handleChange = (dateRange: IDateRangeValue) => {
      setValue(name, transformValue?.(dateRange) ?? dateRange)
    }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <TextField
            {...field}
            fullWidth
            error={!!error}
            helperText={error?.message}
            value={inputValue}
            onClick={handleOpen}
            {...fieldProps}
            {...(withClearButton && {
              InputProps: {
                endAdornment: (
                  <Tooltip title={t('common:clear')}>
                    <IconButton
                      size='small'
                      sx={{ visibility: inputValue ? 'visible' : 'hidden' }}
                      onClick={() => setValue(name, null)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                ),
              },
            })}
          />

          {openedCalendar && (
            <CalendarDateRange
              {...other}
              initialDateRange={rangeDate}
              opened={openedCalendar}
              toggle={toggleCalendar}
              onChange={handleChange as unknown as ICalendarDateRangeProps['onChange']}
              wrapperProps={{ anchorEl, ...other?.wrapperProps }}
            />
          )}
        </>
      )}
    />
  )
}
