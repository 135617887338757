import React from 'react'
const Pluzze = (props) => (
  <svg {...props} xmlns='http://www.w3.org/2000/svg' width='28.043' height='28.043' viewBox='0 0 28.043 28.043'>
    <g transform='translate(0 0)'>
      <path
        className='a'
        d='M13.2,220.161v-3.3h-.415a3.3,3.3,0,1,1,0-6.591H13.2v-3.3H9.074a.822.822,0,0,1-.822-.822V204.9a1.652,1.652,0,1,0-3.3,0v1.237a.822.822,0,0,1-.822.822H0v11.548a1.654,1.654,0,0,0,1.652,1.652Z'
        transform='translate(0 -192.118)'
      />
      <path
        className='a'
        d='M277.6,16.911a1.654,1.654,0,0,0,1.652-1.652V14.022a.822.822,0,0,1,.822-.822H284.2V1.652A1.654,1.654,0,0,0,282.548,0H271V3.3h.415a3.3,3.3,0,0,1,0,6.591H271v3.3h4.126a.822.822,0,0,1,.822.822v1.237A1.654,1.654,0,0,0,277.6,16.911Z'
        transform='translate(-256.157 0)'
      />
      <path
        className='a'
        d='M1.652,0A1.654,1.654,0,0,0,0,1.652V13.2H3.3v-.415a3.3,3.3,0,0,1,6.591,0V13.2h3.3V9.074a.822.822,0,0,1,.822-.822h1.237a1.652,1.652,0,0,0,0-3.3H14.022a.822.822,0,0,1-.822-.822V0Z'
        transform='translate(0)'
      />
    </g>
  </svg>
)
export default Pluzze
