import React from 'react'

const TripleBlox = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' {...props}>
    <defs>
      <clipPath id='a'>
        <rect className='a' width='30' height='30' transform='translate(0.295)' />
      </clipPath>
    </defs>
    <g className='b' transform='translate(-0.295)'>
      <g transform='translate(3.202 -0.18)'>
        <path
          className='c'
          d='M26.413,6.765,19.941.292A1,1,0,0,0,19.235,0H4.293a1,1,0,0,0-1,1V29a1,1,0,0,0,1,1H25.707a1,1,0,0,0,1-1V7.471A1,1,0,0,0,26.413,6.765Zm-6.18-3.356L23.3,6.473H20.233ZM5.291,28V2H18.236V7.471a1,1,0,0,0,1,1h5.474V28H5.291Z'
          transform='translate(-3.294 0)'
        />
        <path
          className='c'
          d='M15.1,13.806a1,1,0,0,0-1.854,0L9.955,22.042a1,1,0,1,0,1.854.742l.408-1.02h3.919l.408,1.02a1,1,0,1,0,1.854-.742Zm-2.088,5.961,1.161-2.9,1.161,2.9Z'
          transform='translate(-3.294 0)'
        />
        <path
          className='c'
          d='M21.369,13.478h-.8v-.808a1,1,0,0,0-2,0v.808h-.8a1,1,0,1,0,0,2h.8v.808a1,1,0,1,0,2,0v-.808h.8a1,1,0,1,0,0-2Z'
          transform='translate(-3.294 0)'
        />
      </g>
    </g>
  </svg>
)

export default TripleBlox
