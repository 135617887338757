export const allowStudentImpersonate = () =>
  new Promise((resolve) => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    resolve(
      (userInfo &&
        userInfo.data.profiles.some((profile) =>
          profile.profile_permissions.find((permission) => permission.permission.key === 'impersonate_student'),
        )) ||
        userInfo.data.role.includes('admin'),
    )
  })
