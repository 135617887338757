import React from 'react'
import { useTranslation } from 'react-i18next'

import LanguageIcon from '@mui/icons-material/Language'
import { Box, MenuItem, Select } from '@mui/material'

export default function SelectLanguage({ mb = '40px' }) {
  const { i18n } = useTranslation()

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng.target.value)
    document.location.reload(true)
  }

  return (
    <Box marginBottom={mb} display='flex' alignItems='center'>
      <LanguageIcon width='25px' height='22px' style={{ marginRight: 8, color: '#39414A', opacity: 0.8 }} />
      <Select
        value={i18n.language}
        defaultValue={i18n.language}
        labelId='select-label'
        id='select'
        label='Português'
        onChange={(e) => handleLanguageChange(e)}
      >
        <MenuItem value={'pt-BR'}>Português</MenuItem>
        <MenuItem value={'en'}>Inglês</MenuItem>
      </Select>
    </Box>
  )
}
