import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

import Application from './modules'

import 'react-lazy-load-image-component/src/effects/blur.css'
import 'react-lazy-load-image-component/src/effects/opacity.css'
import 'react-lazy-load-image-component/src/effects/black-and-white.css'
import 'react-quill/dist/quill.snow.css'
import 'common/assets/css/index.css'
import 'common/util/highlight'
import './translations'

toast.configure()

const App = (): JSX.Element => (
  <Suspense fallback={<span />}>
    <BrowserRouter>
      <Application />
    </BrowserRouter>
    <ToastContainer />
  </Suspense>
)

export default App
