import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { MenuItem, FormControl, Select } from '@mui/material'
import { writeStorage } from '@rehooks/local-storage'

import { translateRoles } from 'common/util/roles'

import { useStyles } from './styles'

export const SelectRole = ({ userInfo }) => {
  const history = useHistory()
  const classes = useStyles()

  const handleRoleChange = useCallback(
    ({ role }) => {
      const formatUserInfo = {
        data: {
          ...userInfo.data,
          role: role,
        },
      }

      writeStorage('userInfo', formatUserInfo)
      history.push('/')
    },
    [userInfo],
  )

  const roles = useMemo(() => {
    const list = translateRoles(userInfo.data.roles)
    const current = list.find((item) => userInfo.data.role.includes(item.role))

    return {
      list,
      current,
    }
  }, [userInfo.data.roles, userInfo.data.role])

  return (
    <FormControl variant='standard' className={classes.formControl}>
      <Select
        className={classes.select}
        value={roles.current}
        onChange={(e) => handleRoleChange(e.target.value)}
        inputProps={{
          classes: {
            icon: classes.icon,
          },
        }}
      >
        {roles.list.map((role) => (
          <MenuItem
            className={classes.menuItem}
            key={role.role}
            value={role}
            {...(role === roles.current && { style: { display: 'none' } })}
          >
            {role.roleTranslated}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
