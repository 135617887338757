import Location from 'app-location'
import * as Yup from 'yup'

export const myLibraryEndpoint = new Location('/v2/services/third_party_apps/minha_biblioteca_create_autenticated_url')

export const genneraEndpoint = new Location('/v2/services/third_party_apps/gennera_create_user_token')

export const bigBlueButtonEndpoint = new Location('/v2/services/third_party_apps/join_big_blue_button_classroom')

export const thirdPartyApps = new Location('/v2/third_party_apps')

export const thirdPartyApp = new Location('/v2/third_party_apps/:appId', {
  appId: Yup.number().required(),
})
