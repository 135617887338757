/* eslint-disable react/no-unknown-property */
import React from 'react'
const Dash = (props) => (
  <svg {...props} xmlns='http://www.w3.org/2000/svg' width='35' height='35' viewBox='0 0 35 35'>
    <defs>
      <clipPath id='a'>
        <rect fill='#000' width='35' height='35' />
      </clipPath>
    </defs>
    <g className='b'>
      <path
        fill='#000'
        className='d'
        d='M2.375,16.958H14.042V2.375H2.375Zm0,11.667H14.042v-8.75H2.375Zm14.583,0H28.625V14.042H16.958Zm0-26.25v8.75H28.625V2.375Z'
        transform='translate(2 2)'
      />
    </g>
  </svg>
)
export default Dash
