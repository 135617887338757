import React, { useEffect, useRef, useState } from 'react'

import { TextField } from '@mui/material'

interface IProps {
  slots: string
  placeholder: string
  accept?: RegExp
  onFinish: (value: string) => void
  component?: React.FC<any>
  componentProps: any
  outsideValue?: string
}

export default function MaskedField({
  outsideValue,
  placeholder,
  slots,
  accept = /\d/g,
  onFinish,
  component: Component = TextField,
  componentProps,
}: IProps) {
  const inputRef = useRef<HTMLInputElement | null>(null),
    [value, setValue] = useState<string>('')

  useEffect(() => {
    const pattern = placeholder
    const slotSet = new Set(slots)
    const prev = ((j) => Array.from(pattern, (c, i) => (slotSet.has(c) ? (j = i + 1) : j)))(0)
    const first = [...pattern].findIndex((c) => slotSet.has(c))

    const clean = (input: any) => {
      input = input.match(accept) || []
      return Array.from(pattern, (c) => (input[0] === c || slotSet.has(c) ? input.shift() || c : c))
    }

    const format = () => {
      if (!inputRef.current) return

      const input = inputRef.current.value
      const [start, end] = [inputRef.current.selectionStart, inputRef.current.selectionEnd].map((i) => {
        i = clean(input.slice(0, i ?? undefined)).findIndex((c) => slotSet.has(c))
        return i < 0 ? prev[prev.length - 1] : back ? prev[i - 1] || first : i
      })
      const formattedValue = clean(input).join('')
      inputRef.current.value = formattedValue
      inputRef.current.setSelectionRange(start, end)
      setValue(formattedValue)
    }

    let back = false
    const handleKeyDown = (e: any) => {
      back = e.key === 'Backspace'
    }

    const handleInput = () => {
      format()

      // check has been fully filled with values or empty
      if (
        Array.from(pattern, (c, index) => (c === '_' ? !!inputRef.current?.value[index]?.match(accept) : true)).every(
          Boolean,
        ) ||
        inputRef.current?.value === pattern
      ) {
        onFinish(inputRef.current?.value ?? '')
      }
    }

    const handleFocus = () => {
      format()
    }

    // onBlur -> reset inputRef when input is empty
    const handleBlur = () => {
      if (inputRef.current?.value === pattern) {
        inputRef.current.value = ''
        setValue('')
      }
    }

    if (!inputRef.current) return

    inputRef.current.addEventListener('keydown', handleKeyDown)
    inputRef.current.addEventListener('input', handleInput)
    inputRef.current.addEventListener('focus', handleFocus)
    inputRef.current.addEventListener('blur', handleBlur)

    return () => {
      inputRef.current?.removeEventListener('keydown', handleKeyDown)
      inputRef.current?.removeEventListener('input', handleInput)
      inputRef.current?.removeEventListener('focus', handleFocus)
      inputRef.current?.removeEventListener('blur', handleBlur)
    }
  }, [placeholder, slots, accept])

  // check if value was changed from outside the component, and update the input value
  useEffect(() => {
    if (value !== outsideValue) {
      setValue(outsideValue ?? '')
    }
  }, [outsideValue])

  return <Component {...componentProps} inputRef={inputRef} inputProps={{ ...componentProps?.inputProps, value }} />
}
