import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Box, FormControl, FormGroup, Grid, Typography } from '@mui/material'

import { RHFAutocomplete, RHFCheckbox, RHFTextField } from 'common/components/hook-form'

import listMedia from '../../mocks/listMedia.json'
import { Container, Label } from './styles'

export const InformationComplementary = (): JSX.Element => {
  const { t } = useTranslation()

  const { watch, setValue } = useFormContext(),
    social_media = watch('social_media'),
    hasSocialMedia = watch('has_social_media_yes')

  useEffect(() => {
    if (!hasSocialMedia && social_media?.length) {
      setValue('social_media', undefined)
    }
  }, [hasSocialMedia])

  return (
    <Container>
      <Box mb={5}>
        <Typography variant='h6'>{t('menu:informationComplement')}</Typography>
        <Typography variant='body2' sx={{ fontSize: '0.8rem' }}>
          {t('menu:createRegistration')}
        </Typography>
      </Box>
      <Grid container sx={{ pr: 2 }}>
        <FormControl component='fieldset'>
          <Label>{t('menu:has_social_media')}</Label>
          <FormGroup aria-label='position' row>
            <Box onChange={() => setValue('has_social_media_no', false)}>
              <RHFCheckbox name='has_social_media_yes' label={t('menu:yes')} labelPlacement='start' value='yes' />
            </Box>
            <Box onChange={() => setValue('has_social_media_yes', false)}>
              <RHFCheckbox name='has_social_media_no' label={t('menu:no')} labelPlacement='start' value='no' />
            </Box>
          </FormGroup>
        </FormControl>
        <Grid item xs={12} mt={1} mb={1}>
          <RHFAutocomplete
            disabled={!hasSocialMedia}
            multiple
            name='social_media'
            textFieldProps={{ label: 'Se sim, quais?', variant: 'outlined' }}
            options={listMedia}
            getOptionLabel={(option) => option.name}
          />
        </Grid>

        {social_media?.map(({ id, name }: { id: number; name: string }) => (
          <Grid item xs={12} mt={1} mb={1} key={id}>
            <Typography variant='body2'>{`Nome de usuário no ${name}`}</Typography>
            <RHFTextField variant='outlined' name={`${name.toLowerCase()}_user`} />
          </Grid>
        ))}

        <Grid item xs={12} mt={1} mb={2}>
          <Typography variant='body2'>{t('menu:technicalFeatures')}</Typography>

          <RHFTextField
            name='technical_features'
            label
            id='outlined-multiline-static'
            multiline
            rows={3}
            variant='outlined'
            style={{ width: '100%', height: '95px' }}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
