import React from 'react'

const BloxBlock = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='26.401' height='30.043' viewBox='0 0 26.401 30.043' {...props}>
    <path
      fill='#0090d0'
      d='M22.532,0l7.511,13.2L22.532,26.4H7.511L0,13.2,7.511,0Z'
      transform='translate(26.401) rotate(90)'
    />
  </svg>
)

export default BloxBlock
