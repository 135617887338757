import Location from 'app-location'
import * as Yup from 'yup'

const registerUserProfessor = new Location('/sabia/professor_registration')
const getUnits = new Location('/v2/sabia_units')

const registerUserSearchProfessor = new Location('/sabia/professor_registration/:id', {
  id: Yup.number().required(),
})

export { registerUserProfessor, registerUserSearchProfessor, getUnits }
