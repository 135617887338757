import React from 'react'

import { useLocalStorage, writeStorage } from '@rehooks/local-storage'

import useInternalImpersonation from './useInternalImpersonation'

const KEY = 'userInfo'

export const useInfo = () => {
  const [userInfo] = useLocalStorage(KEY)

  const { impersonationUserInfo } = useInternalImpersonation()

  const write = React.useCallback((data) => writeStorage(KEY, { data }), [KEY])
  return [impersonationUserInfo || userInfo, write]
}
