import React from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import SelectLanguage from 'common/components/SelectLanguageComponent'

import BloxLogo from '../assets/images/logo_blox.svg'
import('../assets/css/Footer.css')

const Footer = ({ year }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <SelectLanguage mb={0} />
      <div>
        <img alt='logo_blox' src={BloxLogo} />
        <p>
          © {year} - Blox - {t('footer:text')} |
        </p>
        <a target='_blank' href='https://app-api.blox.education/privacy-policy-blox.pdf' rel='noreferrer'>
          {t('footer:privacy')}
        </a>
      </div>
      <div />
    </Container>
  )
}
export default Footer

const Container = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-around;
  div {
    flex-direction: row;
    display: flex;
  }
  div img {
    height: 30px;
    margin-right: 16px;
  }
  div p {
    margin-right: 8px;
  }
  div a {
    color: #636063;
  }

  @media print {
    display: none;
  }
`
