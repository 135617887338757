import dashboard from './admin/dashboard.json'
import enrollments from './admin/enrollments.json'
import equivalencies from './admin/equivalencies.json'
import areaDescriptions from './admin/general-parameters/area-descriptions.json'
import auditLogs from './admin/general-parameters/auditLogs.json'
import courses from './admin/general-parameters/courses.json'
import customLinks from './admin/general-parameters/custom-links.json'
import cycles from './admin/general-parameters/cycles.json'
import educationLevels from './admin/general-parameters/education-levels.json'
import externalTools from './admin/general-parameters/externalTools.json'
import profiles from './admin/general-parameters/profiles.json'
import shifts from './admin/general-parameters/shifts.json'
import units from './admin/general-parameters/units.json'
import InstitutionColor from './admin/institution-color.json'
import institutionLogo from './admin/institution-logo.json'
import bibliographyTypes from './admin/pedagogical-parameters/bibliography-types.json'
import bloxProfiles from './admin/pedagogical-parameters/blox-profiles.json'
import bloxTemplates from './admin/pedagogical-parameters/blox-templates.json'
import competences from './admin/pedagogical-parameters/competences.json'
import equivalences from './admin/pedagogical-parameters/equivalences.json'
import functionalAreas from './admin/pedagogical-parameters/functional-areas.json'
import knowledgeAreas from './admin/pedagogical-parameters/knowledge-areas.json'
import levelEducation from './admin/pedagogical-parameters/levelEducation.json'
import methodologies from './admin/pedagogical-parameters/methodologies.json'
import teachingApproach from './admin/pedagogical-parameters/teachingApproach.json'
import bloxDetail from './blox-details.json'
import common from './common/common.json'
import users from './common/users.json'
import breadcrumb from './components/breadcrumb.json'
import button from './components/button.json'
import CustomCalendar from './components/custom-calendar.json'
import footer from './components/footer.json'
import legend from './components/legend.json'
import loading from './components/loading.json'
import menu from './components/menu.json'
import pickers from './components/pickers.json'
import table from './components/table.json'
import virtualRoom from './components/virtual-room.json'
import bloxCreation from './coordinator/blox-creation.json'
import curricularUnits from './coordinator/curricular-units.json'
import emailNotifications from './coordinator/email-Notifications.json'
import offerings from './coordinator/offerings.json'
import studentResets from './coordinator/student-resets.json'
import students from './coordinator/students.json'
import yearExercises from './coordinator/year-exercises.json'
import global from './global.json'
import assessment from './lms/assessment.json'
import assessments from './lms/assessments.json'
import groupActivities from './lms/group-activities.json'
import lectureConfig from './lms/lecture-config.json'
import myClass from './lms/my-class.json'
import virtualLearning from './lms/virtualLearning.json'
import login from './login.json'
import professorMenu from './professor/menu.json'
import bloxHistory from './student/blox-history.json'
import bloxSelection from './student/blox-selection.json'
import certificate from './student/certificate.json'
import charts from './student/charts.json'
import classroom from './student/classroom.json'
import formationRules from './student/formationRules.json'
import modal from './student/modal.json'
import schedule from './student/schedule'
import UserNotification from './student/User_Notification.json'

export default {
  'blox-details': bloxDetail,
  'blox-creation': bloxCreation,
  'institution-logo': institutionLogo,
  'institution-color': InstitutionColor,
  'knowledge-areas': knowledgeAreas,
  'functional-areas': functionalAreas,
  'teaching-Approach': teachingApproach,
  'level-Education': levelEducation,
  'bibliography-types': bibliographyTypes,
  'blox-profiles': bloxProfiles,
  'area-descriptions': areaDescriptions,
  'education-levels': educationLevels,
  'custom-links': customLinks,
  'blox-selection': bloxSelection,
  'blox-history': bloxHistory,
  'year-exercises': yearExercises,
  'email-Notifications': emailNotifications,
  'my-class': myClass,
  'curricular-units': curricularUnits,
  'group-activities': groupActivities,
  'blox-templates': bloxTemplates,
  calendar: CustomCalendar,
  assessment,
  loading,
  menu,
  button,
  legend,
  breadcrumb,
  table,
  dashboard,
  equivalencies,
  competences,
  methodologies,
  footer,
  login,
  units,
  courses,
  cycles,
  shifts,
  users,
  offerings,
  charts,
  modal,
  students,
  global,
  professorMenu,
  profiles,
  UserNotification,
  certificate,
  schedule,
  common,
  formationRules,
  equivalences,
  externalTools,
  studentResets,
  lectureConfig,
  virtualRoom,
  classroom,
  assessments,
  virtualLearning,
  enrollments,
  auditLogs,
  pickers,
}
