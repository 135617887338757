import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, FormControl, FormGroup, Grid, MenuItem, Typography } from '@mui/material'

import { RHFCheckbox, RHFSelect, RHFTextField } from 'common/components/hook-form'

import modalHooks from '../../function'
import listSelect from '../../mocks/listSelect.json'
import listYears from '../../mocks/listYears.json'
import { Container, Label } from './styles'

export const AcademicEducation = (): any => {
  const { t } = useTranslation()
  const { disableFields, disableField } = modalHooks()

  return (
    <Container>
      <Box mb={5}>
        <Typography variant='h6'>{t('menu:academicEducation')}</Typography>
        <Typography variant='body2' sx={{ fontSize: '0.8rem' }}>
          {t('menu:createRegistration')}
        </Typography>
      </Box>
      <Grid container sx={{ pr: 2 }}>
        <Grid item xs={12}>
          <FormControl component='fieldset'>
            <Label>{t('menu:universityGraduate')}</Label>
            <FormGroup aria-label='position' row>
              <Box onChange={() => disableField(['graduation_status_inprogress', 'graduation_status_no'])}>
                <RHFCheckbox
                  name='graduation_status_yes'
                  label={t('menu:yes')}
                  value='yes'
                  disabled={disableFields.graduation_status_yes}
                />
              </Box>
              <Box onChange={() => disableField(['graduation_status_yes', 'graduation_status_inprogress'])}>
                <RHFCheckbox
                  name='graduation_status_no'
                  label={t('menu:no')}
                  value='no'
                  disabled={disableFields.graduation_status_no}
                />
              </Box>
              <Box onChange={() => disableField(['graduation_status_yes', 'graduation_status_no'])}>
                <RHFCheckbox
                  name='graduation_status_inprogress'
                  label={t('common:inProgress')}
                  value='inprogress'
                  disabled={disableFields.graduation_status_inprogress}
                />
              </Box>
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} mt={1} mb={1}>
          <RHFSelect
            name='graduation_course'
            type='text'
            variant='outlined'
            label={t('menu:selectCourse')}
            disabled={disableFields.graduation_status_yes && disableFields.graduation_status_inprogress}
          >
            {listSelect?.map((item) => (
              <MenuItem key={item?.id} value={item?.name}>
                {item?.name}
              </MenuItem>
            ))}
          </RHFSelect>
        </Grid>
        <Grid item xs={12}>
          <FormControl component='fieldset'>
            <Label>{t('menu:specialization')}</Label>
            <FormGroup aria-label='position' row>
              <Box onChange={() => disableField(['specialization_status_no', 'specialization_status_inprogress'])}>
                <RHFCheckbox
                  name='specialization_status_yes'
                  label={t('menu:yes')}
                  value='yes'
                  disabled={disableFields.specialization_status_yes}
                />
              </Box>
              <Box onChange={() => disableField(['specialization_status_yes', 'specialization_status_inprogress'])}>
                <RHFCheckbox
                  name='specialization_status_no'
                  label={t('menu:no')}
                  value='no'
                  disabled={disableFields.specialization_status_no}
                />
              </Box>
              <Box onChange={() => disableField(['specialization_status_yes', 'specialization_status_no'])}>
                <RHFCheckbox
                  name='specialization_status_inprogress'
                  label={t('common:inProgress')}
                  value='inprogress'
                  disabled={disableFields.specialization_status_inprogress}
                />
              </Box>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} mt={1} mb={1}>
          <RHFTextField
            name='specialization_course'
            type='text'
            label={t('menu:specificArea')}
            variant='outlined'
            disabled={disableFields.specialization_status_yes && disableFields.specialization_status_inprogress}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component='fieldset'>
            <Label>{t('menu:masters')}</Label>
            <FormGroup aria-label='position' row>
              <Box onChange={() => disableField(['masters_degree_status_no', 'masters_degree_status_inprogress'])}>
                <RHFCheckbox
                  name='masters_degree_status_yes'
                  label={t('menu:yes')}
                  value='yes'
                  disabled={disableFields.masters_degree_status_yes}
                />
              </Box>
              <Box onChange={() => disableField(['masters_degree_status_yes', 'masters_degree_status_inprogress'])}>
                <RHFCheckbox
                  name='masters_degree_status_no'
                  label={t('menu:no')}
                  value='no'
                  disabled={disableFields.masters_degree_status_no}
                />
              </Box>
              <Box onChange={() => disableField(['masters_degree_status_yes', 'masters_degree_status_no'])}>
                <RHFCheckbox
                  name='masters_degree_status_inprogress'
                  label={t('common:inProgress')}
                  value='inprogress'
                  disabled={disableFields.masters_degree_status_inprogress}
                />
              </Box>
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} mt={1} mb={1}>
          <RHFTextField
            name='masters_degree_course'
            type='text'
            label={t('menu:specificArea')}
            variant='outlined'
            disabled={disableFields.masters_degree_status_yes && disableFields.masters_degree_status_inprogress}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component='fieldset'>
            <Label>{t('menu:degree')}</Label>
            <FormGroup aria-label='position' row>
              <Box onChange={() => disableField(['degree_status_no', 'degree_status_inprogress'])}>
                <RHFCheckbox
                  name='degree_status_yes'
                  label={t('menu:yes')}
                  value='yes'
                  disabled={disableFields.degree_status_yes}
                />
              </Box>
              <Box onChange={() => disableField(['degree_status_yes', 'degree_status_inprogress'])}>
                <RHFCheckbox name='degree_status_no' label={t('menu:no')} disabled={disableFields.degree_status_no} />
              </Box>
              <Box onChange={() => disableField(['degree_status_yes', 'degree_status_no'])}>
                <RHFCheckbox
                  name='degree_status_inprogress'
                  label={t('common:inProgress')}
                  value='inprogress'
                  disabled={disableFields.degree_status_inprogress}
                />
              </Box>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} mt={1} mb={1}>
          <RHFTextField
            name='degree_course'
            type='text'
            label={t('menu:specificArea')}
            variant='outlined'
            disabled={disableFields.degree_status_yes && disableFields.degree_status_inprogress}
          />
        </Grid>
        <Grid item xs={12} mt={1} mb={1}>
          <Typography variant='body2'>{t('menu:formation')}</Typography>
          <RHFSelect name='time_experience' type='text' variant='outlined' label>
            {listYears?.map((item) => (
              <MenuItem key={item?.id} value={item?.name}>
                {item?.name}
              </MenuItem>
            ))}
          </RHFSelect>
        </Grid>
      </Grid>
    </Container>
  )
}
