export const getTimestamp = (date) => {
  const day = date.getDay() * 24 * 60
  const hour = date.getHours() * 60
  const minute = date.getMinutes()

  return day + hour + minute
}

export const mergeEvents = (events) =>
  events
    .sort((a, b) => {
      const timeA = getTimestamp(a.start)
      const timeB = getTimestamp(b.start)
      return timeA - timeB
    })
    .reduce((acc, event, index, object) => {
      const actualId = event?.blox?.id
      const actualEnd = getTimestamp(event.end)
      const nextEvent = events[index + 1]
      const nextId = nextEvent?.blox?.id

      if (nextId === actualId) {
        const nextStart = getTimestamp(nextEvent.start)

        if (nextStart - actualEnd === 1) {
          object.splice(index + 1, 1)
          return [...acc, { ...event, end: nextEvent.end }]
        }
      }
      return [...acc, event]
    }, [])

export const sortEvents = (events) =>
  events?.sort((a, b) => {
    const timeA = getTimestamp(a.start)
    const timeB = getTimestamp(b.start)
    return timeA - timeB
  })
