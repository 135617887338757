import { ButtonProps, styled, Button as MuiButton } from '@mui/material'

export const Button = styled(MuiButton)<ButtonProps>({
  border: 'none',
  background: '#00AB55',
  color: '#FFFFFF',
  fontSize: '10px',
  borderRadius: '5px',
  textTransform: 'inherit',
  boxShadow: '0px 3px 6px #00000029',
  '&:hover': {
    backgroundColor: '#0a542f',
    opacity: '0.9',
  },
})
