import * as Yup from 'yup'

export const signInParametersSchema = Yup.object().shape({
  email: Yup.string().required('Campo obrigatório'),
})

export const registerParametersSchema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  cpf: Yup.string()
    .matches(/(\d{3})(\d{3})(\d{3})(\d{2})/, 'Informe um CPF válido')
    .required('Informe CPF válido'),
  bday: Yup.date().typeError('Campo obrigatório').required(),
  email: Yup.string().email('Informe um e-mail válido').required('Campo obrigatório'),
  password: Yup.string()
    .min(8, 'Campo obrigatório')
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/,
      'A senha deve conter no mínimo 8 caracteres, uma letra maiúscula e um número',
    )
    .required(),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais'),
})

export const registerFormParametersSchema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  cpf: Yup.string()
    .matches(/(\d{3})(\d{3})(\d{3})(\d{2})/, 'digite somente números')
    .required('Informe CPF válido'),
  email: Yup.string()
    .email('Informe um e-mail válido')
    .required('Campo obrigatório')
    .typeError('Informe um e-mail válido'),
  password: Yup.string()
    .min(8, 'A senha deve conter no mínimo 8 caracteres')
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/,
      'A senha deve conter no mínimo 8 caracteres, uma letra maiúscula e um número',
    )
    .required('A senha deve conter no mínimo 8 caracteres, uma letra maiúscula e um número'),
  password_confirmation: Yup.string().when('password', (password, field) =>
    password ? field.required('Campo obrigatório').oneOf([Yup.ref('password')], 'As senhas devem ser iguais') : field,
  ),
  phone: Yup.string()
    .min(10, 'Numero de telefone inválido')
    .test('validatephone', 'Numero de telefone inválido', (value) => {
      if (!value) return

      const phoneFormatReg = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/
      const matches = (value || '').match(/\d+/g)?.join('')?.length || 0

      if (matches >= 11 && matches <= 14) {
        return phoneFormatReg.test(value)
      }
    })
    .required('Campo obrigatório'),
  rg: Yup.string().required('Campo obrigatório'),
  cep: Yup.string().required('Campo obrigatório'),
  residential_number: Yup.string().required('Campo obrigatório'),
})
export const registerFormProfessorSchema = Yup.object().shape({
  graduation_status_yes: Yup.boolean(),
  specialization_status_yes: Yup.boolean(),
  specialization_course: Yup.string()
    .when('specialization_status_yes', (specialization_status_yes, field) =>
      specialization_status_yes ? field.required('Campo obrigatório') : field,
    )
    .when('specialization_status_inprogress', (specialization_status_inprogress, field) =>
      specialization_status_inprogress ? field.required('Campo obrigatório') : field,
    ),
  masters_degree_status_yes: Yup.boolean(),
  degree_status_yes: Yup.boolean(),
  degree_course: Yup.string()
    .when('degree_status_yes', (degree_status_yes, field) =>
      degree_status_yes ? field.required('Campo obrigatório') : field,
    )
    .when('specialization_status_inprogress', (degree_status_inprogress, field) =>
      degree_status_inprogress ? field.required('Campo obrigatório') : field,
    ),
  graduation_course: Yup.string()
    .when('graduation_status_yes', (graduation_status_yes, field) =>
      graduation_status_yes ? field.required('Campo obrigatório') : field,
    )
    .when('specialization_status_inprogress', (graduation_status_inprogress, field) =>
      graduation_status_inprogress ? field.required('Campo obrigatório') : field,
    ),
  masters_degree_course: Yup.string()
    .when('masters_degree_status_yes', (masters_degree_status_yes, field) =>
      masters_degree_status_yes ? field.required('Campo obrigatório') : field,
    )
    .when('specialization_status_inprogress', (masters_degree_status_inprogress, field) =>
      masters_degree_status_inprogress ? field.required('Campo obrigatório') : field,
    ),

  time_experience: Yup.string().required('Campo obrigatório'),
  has_social_media_yes: Yup.boolean(),
  social_media: Yup.mixed().when('has_social_media_yes', (has_social_media_yes, field) =>
    has_social_media_yes ? field.required('Campo obrigatório') : field,
  ),
  technical_features: Yup.string().required('Campo obrigatório'),
  have_acessible_computer_lab: Yup.string().required('Campo obrigatório'),
  rooms: Yup.string().required('Campo obrigatório'),
})
