import React from 'react'
import { Controller, ControllerProps as RHFControllerProps } from 'react-hook-form'

export type TransformType<FieldValue, FieldInput, FieldOutput> = {
  input: (value: FieldValue) => FieldValue
  output: (event: FieldInput) => FieldOutput
}

type RenderArguments<FieldValue, FieldInput, FieldOutput> = Parameters<RHFControllerProps['render']>[number] & {
  transform?: TransformType<FieldValue, FieldInput, FieldOutput>
}

export type ControllerProps<FieldValue = any, FieldInput = any, FieldOutput = any> = Omit<
  RHFControllerProps,
  'render'
> & {
  transform?: TransformType<FieldValue, FieldInput, FieldOutput>
  render: (args: RenderArguments<FieldValue, FieldInput, FieldOutput>) => React.ReactElement
}

const ControllerPlus = <FieldValue, FieldInput, FieldOutput>({
  control,
  transform,
  name,
  defaultValue,
  render,
}: ControllerProps<FieldValue, FieldInput, FieldOutput>) => (
  <Controller
    defaultValue={defaultValue}
    control={control}
    name={name}
    render={(args) => render({ ...args, transform })}
  />
)

export default ControllerPlus
