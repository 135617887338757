import styled from 'styled-components'

const Divider = styled.hr`
  width: 100%;
  ${({ color = '#E9E9F0', size = 1 }) =>
    `
      margin: 7px 0;
      color: ${color};
      min-height: ${size}px;
    `}
`

export default Divider
