import {
  startOfWeek,
  startOfMonth,
  endOfWeek,
  endOfMonth,
  isBefore,
  addDays,
  isSameDay,
  isWithinInterval,
  isSameMonth,
  addMonths,
  parseISO,
  isValid,
  min,
  max,
} from 'date-fns'
import { IDateRange } from 'types/components/calendarDateRange'

const identity = <T>(x: T) => x,
  chunks = <T>(array: ReadonlyArray<T>, size: number): T[][] =>
    Array.from({ length: Math.ceil(array.length / size) }, (_v, i) => array.slice(i * size, i * size + size))

const getDaysInMonth = (date: Date, locale?: Locale) => {
  const startWeek = startOfWeek(startOfMonth(date), { locale }),
    endWeek = endOfWeek(endOfMonth(date), { locale }),
    days = []
  let curr = startWeek
  while (isBefore(curr, endWeek)) {
    days.push(curr)
    curr = addDays(curr, 1)
  }

  return days
}

const getDayNames = (locale: string) => {
    const formatter = new Intl.DateTimeFormat(locale, { weekday: 'long', timeZone: 'UTC' })
    const days = Array.from({ length: 7 }, (_v, i) => addDays(new Date('2023-01-01T00:00:00+00:00'), i))
    return days.map((date) => formatter.format(date))
  },
  getMonthNames = (locale: string) => {
    const formatter = new Intl.DateTimeFormat(locale, { month: 'long', timeZone: 'UTC' })
    const months = Array.from({ length: 12 }, (_v, i) => addMonths(new Date('2023-01-01T00:00:00+00:00'), i))
    return months.map((date) => formatter.format(date))
  }

const isStartOfRange = ({ start_at }: IDateRange, day: Date) => (start_at && isSameDay(day, start_at)) as boolean

const isEndOfRange = ({ end_at }: IDateRange, day: Date) => (end_at && isSameDay(day, end_at)) as boolean

const inDateRange = ({ start_at, end_at }: IDateRange, day: Date) =>
  (start_at &&
    end_at &&
    (isWithinInterval(day, { start: start_at, end: end_at }) ||
      isSameDay(day, start_at) ||
      isSameDay(day, end_at))) as boolean

const isRangeSameDay = ({ start_at, end_at }: IDateRange) => {
  if (start_at && end_at) {
    return isSameDay(start_at, end_at)
  }
  return false
}

const parseOptionalDate = (date: Date | string | false | null | undefined | 0 | '', defaultValue: Date) => {
  if (date) {
    const parsed = date instanceof Date ? date : parseISO(date)
    if (isValid(parsed)) return parsed
  }
  return defaultValue
}

const getValidatedMonths = (range: IDateRange, minDate: Date, maxDate: Date) => {
  const { start_at, end_at } = range
  if (start_at && end_at) {
    const newStart = max([start_at, minDate])
    const newEnd = min([end_at, maxDate])

    return [newStart, isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd]
  }
  return [start_at, end_at]
}

export {
  identity,
  chunks,
  getDaysInMonth,
  getDayNames,
  getMonthNames,
  isStartOfRange,
  isEndOfRange,
  inDateRange,
  isRangeSameDay,
  parseOptionalDate,
  getValidatedMonths,
}
