import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, Stack } from '@mui/material'
import { registerFormProfessorSchema } from 'modules/public/Offerings/helpers/schemas'

import { FormProvider } from 'common/components/hook-form'
import { notifyToast } from 'common/components/snackbar/Notify'
import { useApi } from 'common/hooks/useApi'
import { useInfo } from 'common/hooks/useInfo'

import { registerUserSearchProfessor } from 'routes/locations/endpoints/sabia'

import modalHooks from '../../function'
import { Button } from './style'

export const ModalForm = ({ open, toggleOpenModal }: any): JSX.Element => {
  const [userInfo, write] = useInfo(),
    { external_data } = userInfo?.data || {}

  const {
    stepPage,
    validateCheckBoxValue,
    validateCheckBoxAcessibleValue,
    validateCheckBoxLocationValue,
    validateCheckBoxTypeValue,
    validateCheckBoxPeriodValue,
    validateCheckBoxHaveValue,
    fields,
    setStepPage,
    Nextpage,
  } = modalHooks()

  const [putCreateUserSearch, loadingCreateUserSearch] = useApi(registerUserSearchProfessor, 'PUT')

  const methods = useForm({
    resolver: yupResolver(registerFormProfessorSchema),
  })

  const { handleSubmit, trigger, reset } = methods

  const handleNavigate = useCallback(
    async function (action: 'PREV' | 'NEXT') {
      const handler = {
        NEXT: async () => {
          const isRequeriedFieldsFilled = await trigger(fields[stepPage])
          if (stepPage < 2 && isRequeriedFieldsFilled) {
            setStepPage(stepPage + 1)
          }
        },
        PREV: () => {
          if (stepPage > 0) {
            setStepPage(stepPage - 1)
          }
        },
      }

      handler[action]()
    },
    [stepPage],
  )

  const onSubmitForm = useCallback(
    async ({
      graduation_status_yes,
      graduation_status_no,
      graduation_status_inprogress,
      degree_status_yes,
      degree_status_no,
      degree_status_inprogress,
      specialization_status_yes,
      specialization_status_no,
      specialization_status_inprogress,
      masters_degree_status_yes,
      masters_degree_status_no,
      masters_degree_status_inprogress,
      specialization_course,
      degree_course,
      time_experience,
      has_social_media_yes,
      has_social_media_no,
      social_media,
      technical_features,
      general_building_conditions_good,
      general_building_conditions_rules,
      general_building_conditions_terrible,
      rooms,
      location_urban,
      location_rural,
      type_private,
      type_municipal,
      type_state,
      period_morning,
      period_evening,
      period_full,
      have_apm_yes,
      have_apm_inactive,
      have_apm_no,
      have_school_board_yes,
      have_school_board_inactive,
      have_school_board_no,
      have_acessible_computer_lab,
      graduation_course,
      masters_degree_course,
      ...social_user
    }: any): Promise<void> => {
      const newUser = {
        external_data: {
          ...external_data,
          academic_formation: {
            graduation_status:
              (graduation_status_yes && validateCheckBoxValue('graduation_status_yes')) ||
              (graduation_status_no && validateCheckBoxValue('graduation_status_no')) ||
              (graduation_status_inprogress && validateCheckBoxValue('graduation_status_inprogress')),

            graduation_course,
            specialization_status:
              (specialization_status_yes && validateCheckBoxValue('specialization_status_yes')) ||
              (specialization_status_no && validateCheckBoxValue('specialization_status_no')) ||
              (specialization_status_inprogress && validateCheckBoxValue('specialization_status_inprogress')),
            specialization_course,
            masters_degree_status:
              (masters_degree_status_yes && validateCheckBoxValue('specialization_status_inprogress_yes')) ||
              (masters_degree_status_no && validateCheckBoxValue('specialization_status_inprogress_no')) ||
              (masters_degree_status_inprogress && validateCheckBoxValue('specialization_status_inprogress_')),
            degree_status:
              (degree_status_yes && validateCheckBoxValue('specialization_status_inprogress_yes')) ||
              (degree_status_no && validateCheckBoxValue('specialization_status_inprogress_no')) ||
              (degree_status_inprogress && validateCheckBoxValue('specialization_status_inprogress_')),
            degree_course,
            time_experience,
          },
          school: {
            complementary_info: {
              has_social_media:
                (has_social_media_yes && validateCheckBoxValue('has_social_media_yes')) ||
                (has_social_media_no && validateCheckBoxValue('has_social_media_no')),
              ...(social_media?.length && {
                social_media: social_media?.map(({ name }: { name: string }) => {
                  const userKeys = Object.keys(social_user || {}),
                    userKey = userKeys.find((key) => key.includes(name.toLowerCase()))

                  return {
                    source: name,
                    user: userKey ? social_user[userKey] : null,
                  }
                }),
              }),
              technical_features,
              have_acessible_computer_lab,
              general_building_conditions:
                (general_building_conditions_good &&
                  validateCheckBoxAcessibleValue('general_building_conditions_good')) ||
                (general_building_conditions_rules &&
                  validateCheckBoxAcessibleValue('general_building_conditions_rules')) ||
                (general_building_conditions_terrible &&
                  validateCheckBoxAcessibleValue('general_building_conditions_terrible')),
              rooms,
              location:
                (location_urban && validateCheckBoxLocationValue('location_urban')) ||
                (location_rural && validateCheckBoxLocationValue('location_rural')),
              type:
                (type_private && validateCheckBoxTypeValue('type_private')) ||
                (type_municipal && validateCheckBoxTypeValue('type_municipal')) ||
                (type_state && validateCheckBoxTypeValue('type_state')),
              period:
                (period_morning && validateCheckBoxPeriodValue('period_morning')) ||
                (period_evening && validateCheckBoxPeriodValue('period_evening')) ||
                (period_full && validateCheckBoxPeriodValue('period_full')),
              have_apm:
                (have_apm_yes && validateCheckBoxHaveValue('have_apm_yes')) ||
                (have_apm_inactive && validateCheckBoxHaveValue('have_apm_inactive')) ||
                (have_apm_no && validateCheckBoxHaveValue('have_apm_no')),
              have_school_board:
                (have_school_board_yes && validateCheckBoxHaveValue('have_school_board_yes')) ||
                (have_school_board_inactive && validateCheckBoxHaveValue('have_school_board_inactive')) ||
                (have_school_board_no && validateCheckBoxHaveValue('have_school_board_no')),
              masters_degree_course,
            },
          },
        },
      }

      const response = await putCreateUserSearch(newUser, null, { id: userInfo?.data?.id })
      if (response) {
        toggleOpenModal()
        notifyToast('Cadastro realizado com sucesso!!', 'success')
        reset()

        const updateUserInfo = {
          ...userInfo?.data,
          ...newUser,
        }

        write(updateUserInfo)
      }
    },
    [userInfo?.data?.id, toggleOpenModal],
  )

  return (
    <Dialog open={open} fullWidth maxWidth='lg'>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitForm)}>
        <DialogContent>
          <Nextpage />
        </DialogContent>
        <DialogActions>
          <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
            {stepPage >= 1 && (
              <Button type='button' onClick={() => handleNavigate('PREV')}>
                Voltar
              </Button>
            )}

            {stepPage < 2 ? (
              <Button type='button' onClick={() => handleNavigate('NEXT')}>
                Avançar
              </Button>
            ) : (
              <Box>
                <Button type='submit'>
                  {loadingCreateUserSearch ? <CircularProgress size={20} color='success' /> : 'Finalizar'}
                </Button>
              </Box>
            )}
          </Stack>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
}
