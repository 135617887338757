const endpoint = () => {
  const hostname = window && window.location && window.location.hostname
  let endpoint

  switch (hostname) {
    case 'plataformablox.com.br':
    case 'www.plataformablox.com.br':
      endpoint = 'https://app-api.blox.education'
      break
    case 'dev.blox.education':
      endpoint = 'https://api-dev.blox.education'
      break
    case 'homolog.blox.education':
      endpoint = 'https://api-homolog.blox.education'
      break
    case 'staging.blox.education':
      endpoint = 'https://api-staging.blox.education'
      break
    case 'app.blox.education':
      endpoint = 'https://app-api.blox.education'
      break
    default:
      // eslint-disable-next-line no-undef
      endpoint = process.env.REACT_APP_API_URL
  }

  return endpoint
}

export const apiEndpoint = endpoint()
