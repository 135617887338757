export const isBrowser = () => typeof window !== 'undefined'

export const screenWidth = (size) => {
  if (!isBrowser()) {
    return size
  }

  return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
}

export const isMobile = (size) => screenWidth(size) < size

export const isIOS = () => isBrowser() && !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
