import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import { TextFieldProps } from '@mui/material'

import { PasswordInput } from '../inputs/PasswordInput'

type IProps = {
  name: string
}

type Props = IProps & TextFieldProps

export default function RHFPasswordTextField({ name, ...other }: Props) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <PasswordInput
          {...field}
          fullWidth
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          error={!!error}
          helperText={error?.message}
          {...other}
        />
      )}
    />
  )
}
