import { MenuProps } from '@mui/material'

export interface IDateRange {
  // could be undefined on the first render
  start_at?: Date
  end_at?: Date
}

// eslint-disable-next-line no-unused-vars
export type Setter<T> = React.Dispatch<React.SetStateAction<T>> | ((value: T) => void)

export enum NavigationAction {
  // eslint-disable-next-line no-unused-vars
  Previous = -1,

  // eslint-disable-next-line no-unused-vars
  Next = 1,
}

export type DefinedDateRange = {
  start_at: Date
  end_at: Date
  label: string
}

// * Components

export interface IMenuProps {
  dateRange: IDateRange
  minDate: Date
  maxDate: Date
  currentDate: Date
  setDateRange: Setter<IDateRange>
  setDate: Setter<Date>
  helpers: {
    // eslint-disable-next-line no-unused-vars
    inHoverRange: (day: Date) => boolean
  }
  handlers: {
    // eslint-disable-next-line no-unused-vars
    onDayClick: (day: Date) => void
    // eslint-disable-next-line no-unused-vars
    onDayHover: (day: Date) => void
    // eslint-disable-next-line no-unused-vars
    onMonthNavigate: (action: NavigationAction) => void
  }
  locale?: Locale
}

export interface IViewMonthProps {
  value: Date
  dateRange: IDateRange
  minDate: Date
  maxDate: Date
  navState: [boolean, boolean]
  // eslint-disable-next-line no-unused-vars
  setValue: (date: Date) => void
  helpers: {
    // eslint-disable-next-line no-unused-vars
    inHoverRange: (day: Date) => boolean
  }
  handlers: {
    // eslint-disable-next-line no-unused-vars
    onDayClick: (day: Date) => void
    // eslint-disable-next-line no-unused-vars
    onDayHover: (day: Date) => void
    // eslint-disable-next-line no-unused-vars
    onMonthNavigate: (action: NavigationAction) => void
  }
  locale?: Locale
}

export interface IDayProps {
  filled?: boolean
  outlined?: boolean
  highlighted?: boolean
  disabled?: boolean
  startOfRange?: boolean
  endOfRange?: boolean
  onClick?: () => void
  onHover?: () => void
  value: number | string
}

export interface IHeaderProps {
  date: Date
  // eslint-disable-next-line no-unused-vars
  setDate: (date: Date) => void
  nextDisabled: boolean
  prevDisabled: boolean
  onClickNext: () => void
  onClickPrevious: () => void
  locale?: Locale
}

export interface IDateRangeProps {
  opened: boolean
  usesSingleCalendar?: boolean
  initialDateRange?: IDateRange
  minDate?: Date | string
  maxDate?: Date | string
  // eslint-disable-next-line no-unused-vars
  onChange: (dateRange: IDateRange) => void
  locale?: Locale
  disableEdit?: boolean
}

export interface IDateRangeWrapperProps extends React.PropsWithChildren<any> {
  opened: boolean
  toggle: VoidFunction
  disableEdit?: boolean
  initialDateRange?: IDateRange
  definedRanges?: DefinedDateRange[]
  minDate?: Date | string
  maxDate?: Date | string
  // eslint-disable-next-line no-unused-vars
  onChange: (dateRange: IDateRange) => void
  closeOnClickOutside?: boolean
  wrapperProps?: {
    [key: string]: any
  } & Omit<MenuProps, 'open' | 'onClose'>
  [key: string]: any
}
