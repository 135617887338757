import { useMemo } from 'react'

import { useInfo } from './useInfo'

export const useThemeColor = () => {
  const [userInfo] = useInfo(),
    { institution, header_configuration, role } = userInfo?.data ?? {},
    { color_settings } = institution?.settings ?? {},
    { custom_color, color: defaultColor } = header_configuration ?? {}

  const color = useMemo(() => {
    if (custom_color && defaultColor) {
      return defaultColor
    } else if (color_settings?.active) {
      return (role && color_settings && color_settings[role]) || 'rgba(68,138,255, 1)'
    }
    return 'rgba(68,138,255, 1)'
  }, [defaultColor, color_settings, custom_color, role])

  return { color }
}
