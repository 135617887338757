export const roles = (rolesList) => {
  const rolesTypes = [
    {
      value: 'admin',
      weight: 1,
    },
    {
      value: 'coordinator',
      weight: 2,
    },
    {
      value: 'professor',
      weight: 3,
    },
    {
      value: 'student',
      weight: 4,
    },
  ]

  const [rootRole] = rolesList
    .map((role) => rolesTypes.find((item) => item.value === role))
    .sort((a, b) => a.weight - b.weight)

  return rootRole?.value || ''
}

export const rolesTypes = [
  {
    role: 'admin',
    roleTranslated: 'admin',
  },
  {
    role: 'coordinator',
    roleTranslated: 'coordenador',
  },
  {
    role: 'professor',
    roleTranslated: 'professor',
  },
  {
    role: 'student',
    roleTranslated: 'estudante',
  },
]

export const translateRoles = (roleList) => {
  const rolesFiltered = roleList.map((role) => {
    const roleType = rolesTypes.find((item) => item.role === role)

    if (roleType) {
      return roleType
    }
  })

  return rolesFiltered
}
