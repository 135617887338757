import { addDays } from 'date-fns'

const weekDayNumber = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
}

export const prepareDates = ({ day, start, end, rangeStart }) => {
  const startDate = rangeStart
    ? addDays(new Date(rangeStart), weekDayNumber[day])
    : new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + weekDayNumber[day]))

  const [startHours, startMinutes] = start.split(':')
  startDate.setHours(startHours)
  startDate.setMinutes(startMinutes)

  const endDate = rangeStart
    ? addDays(new Date(rangeStart), weekDayNumber[day])
    : new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + weekDayNumber[day]))

  const [endHours, endMinutes] = end.split(':')
  endDate.setHours(endHours)
  endDate.setMinutes(endMinutes)

  return { start: startDate, end: endDate }
}
