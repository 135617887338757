import { useState, useCallback } from 'react'

type UseDiscloseReturn = { isOpen: boolean; onOpen: VoidFunction; onClose: VoidFunction; onToggle: VoidFunction }
type UseDiscloseFunction = (initialState?: boolean) => UseDiscloseReturn

export const useDisclose: UseDiscloseFunction = (initialState) => {
  const [isOpen, setIsOpen] = useState<boolean>(initialState ?? false)

  const onOpen = useCallback(() => setIsOpen(true), [])
  const onClose = useCallback(() => setIsOpen(false), [])
  const onToggle = useCallback(() => setIsOpen((oldState) => !oldState), [])

  return { isOpen, onOpen, onClose, onToggle }
}
