import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import { ToggleButtonProps, ToggleButton, Tooltip, TooltipProps, Typography, Stack } from '@mui/material'

type Props = Omit<ToggleButtonProps, 'value'> & {
  name: string
  icon: React.ReactNode | { checked: React.ReactNode; unchecked: React.ReactNode }
  label?: string
  useTooltip?: boolean
} & (
    | {
        tooltipText: string
        useTooltip: true
        tooltipProps?: Omit<TooltipProps, 'title'>
      }
    | {
        tooltipText?: never
        useTooltip?: false
        tooltipProps?: never
      }
  )

export default function RHFToggle({ name, label, icon, useTooltip, tooltipText, ...other }: Props) {
  const { control } = useFormContext()

  const isIconObject = (icon: Props['icon']): icon is { checked: React.ReactNode; unchecked: React.ReactNode } =>
    !!(typeof icon === 'object' && icon && 'checked' in icon && 'unchecked' in icon)

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, ...fieldProps }, fieldState: { error } }) =>
        useTooltip ? (
          <Tooltip title={error?.message ?? tooltipText ?? ''} placement='top'>
            <ToggleButton
              {...fieldProps}
              {...other}
              value={value}
              selected={value}
              onClick={() => onChange(!value)}
              {...(error ? { color: 'error' } : {})}
            >
              <Stack direction='row' spacing={0.75} alignItems='center'>
                {isIconObject(icon) ? (value ? icon.checked : icon.unchecked) : icon}
                {!!label && (
                  <Typography noWrap variant='button' component='legend' lineHeight='normal'>
                    {label}
                  </Typography>
                )}
              </Stack>
            </ToggleButton>
          </Tooltip>
        ) : (
          <ToggleButton
            {...fieldProps}
            {...other}
            value={value}
            selected={value}
            onClick={() => onChange(!value)}
            {...(error ? { color: 'error' } : {})}
          >
            {isIconObject(icon) ? (value ? icon.checked : icon.unchecked) : icon}
            {!!label && (
              <Typography variant='body1' component='legend' lineHeight='normal'>
                {label}
              </Typography>
            )}
          </ToggleButton>
        )
      }
    />
  )
}
