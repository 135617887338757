import i18n from 'translations'

export const pedagogicalLinks = [
  {
    name: i18n.t('functional-areas:curriculum'),
    path: '/admin/pedagogical-parameters/curriculum',
    actives: ['/admin/pedagogical-parameters/curriculum', '/admin/pedagogical-parameters/unit'],
  },
  {
    name: i18n.t('equivalences:equivalencesRules'),
    path: '/admin/pedagogical-parameters/equivalences',
  },
  {
    name: i18n.t('functional-areas:prerequisites'),
    path: '/admin/pedagogical-parameters/requirements',
  },
  {
    name: i18n.t('functional-areas:corequirement'),
    path: '/admin/pedagogical-parameters/corequirements',
  },
  {
    name: i18n.t('knowledge-areas:name'),
    path: '/admin/pedagogical-parameters/knowledge-areas',
  },
  {
    name: i18n.t('functional-areas:name'),
    path: '/admin/pedagogical-parameters/functional-areas',
  },
  {
    name: i18n.t('knowledge-areas:competences'),
    path: '/admin/pedagogical-parameters/competences',
  },
  {
    name: i18n.t('methodologies:name'),
    path: '/admin/pedagogical-parameters/methodologies',
  },
  {
    name: i18n.t('bibliography-types:name'),
    path: '/admin/pedagogical-parameters/bibliography-types',
  },
  {
    name: i18n.t('profiles:profile'),
    path: '/admin/pedagogical-parameters/blox-profiles',
  },
  {
    name: i18n.t('functional-areas:templates'),
    path: '/admin/pedagogical-parameters/templates',
  },
  {
    name: i18n.t('functional-areas:teachingApproach'),
    path: '/admin/pedagogical-parameters/teaching-Approach',
  },
  {
    name: i18n.t('functional-areas:levelEducation'),
    path: '/admin/pedagogical-parameters/level-Education',
  },
]
