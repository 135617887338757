import { styled, Box, Menu } from '@mui/material'

const Wrapper = styled(Box)(() => ({
  position: 'relative',
  display: 'none',
}))

const CalendarWrapper = styled(Menu)(({ theme }) => ({
  '& ul': {
    padding: 0,
  },
  '.MuiPaper-root': {
    display: 'inline-block',
    borderRadius: theme.shape.borderRadius,
  },
  '.MuiPopover-paper': {
    marginTop: theme.spacing(0.5),
  },
}))

export { Wrapper, CalendarWrapper }
