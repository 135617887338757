/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import ReactDOM from 'react-dom/client'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import { environment } from 'common/config/sentry'

import App from './App'
import reportWebVitals from './reportWebVitals'

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

const env = environment()

if (env) {
  Sentry.init({
    environment: env,
    dsn: 'https://7ea0a1bcb9c24c32a26471dbebe80619@o134678.ingest.sentry.io/6342462',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.2,
  })
}

// Log token for debugging
// eslint-disable-next-line no-undef
process.env.NODE_ENV !== 'production' && console.log(localStorage.getItem('token'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
