const initialState = { content: null }

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET':
      return { ...state, content: action.payload }
    default:
      return state
  }
}
