import React, { useState } from 'react'

import { addMonths, addYears, isAfter, isBefore, isSameDay, isWithinInterval, max, min } from 'date-fns'
import { IDateRangeProps, NavigationAction, IDateRange } from 'types/components/calendarDateRange'

import { getValidatedMonths, parseOptionalDate } from '../utils'
import Menu from './menu'

const DateRangePicker: React.FC<IDateRangeProps> = ({
  opened,
  onChange,
  initialDateRange,
  minDate,
  maxDate,
  locale,
  disableEdit,
}) => {
  const today = new Date()

  const minDateValid = parseOptionalDate(minDate, addYears(today, -10)),
    maxDateValid = parseOptionalDate(maxDate, addYears(today, 10)),
    [initialCurrentMonth] = getValidatedMonths(initialDateRange || {}, minDateValid, maxDateValid),
    [dateRange, setDateRange] = useState<IDateRange>({
      ...initialDateRange,
    }),
    [hoverDay, setHoverDay] = useState<Date>(),
    [currentMonth, setCurrentMonth] = useState<Date>(initialCurrentMonth || today)

  const { start_at, end_at } = dateRange

  const setDateMonthValidated = (date: Date) => {
    setCurrentMonth(date)
  }

  const setDateRangeValidated = (range: IDateRange) => {
    let { start_at: newStart, end_at: newEnd } = range

    if (newStart && newEnd) {
      range.start_at = newStart = max([newStart, minDateValid])
      range.end_at = newEnd = min([newEnd, maxDateValid])

      setDateRange(range)
      onChange(range)

      setCurrentMonth(newStart)
    } else {
      const emptyRange = {}

      setDateRange(emptyRange)
      onChange(emptyRange)

      setCurrentMonth(today)
    }
  }

  const onDayClick = (day: Date) => {
    if (disableEdit) return
    if (start_at && !end_at && !isBefore(day, start_at)) {
      const newRange = { start_at, end_at: day }
      onChange(newRange)
      setDateRange(newRange)
    } else {
      setDateRange({ start_at: day, end_at: undefined })
    }
    setHoverDay(day)
  }

  const onMonthNavigate = (action: NavigationAction) => {
    const newMonth = addMonths(currentMonth, action)
    setCurrentMonth(newMonth)
  }

  const onDayHover = (date: Date) => {
    if (start_at && !end_at) {
      if (!hoverDay || !isSameDay(date, hoverDay)) {
        setHoverDay(date)
      }
    }
  }

  // helpers
  const inHoverRange = (day: Date) =>
    (start_at &&
      !end_at &&
      hoverDay &&
      isAfter(hoverDay, start_at) &&
      isWithinInterval(day, { start: start_at, end: hoverDay })) as boolean

  const helpers = {
    inHoverRange,
  }

  const handlers = {
    onDayClick,
    onDayHover,
    onMonthNavigate,
  }

  return opened ? (
    <Menu
      dateRange={dateRange}
      minDate={minDateValid}
      maxDate={maxDateValid}
      currentDate={currentMonth}
      setDate={setDateMonthValidated}
      setDateRange={setDateRangeValidated}
      helpers={helpers}
      handlers={handlers}
      locale={locale}
    />
  ) : null
}

export default DateRangePicker
