import { Box, BoxProps, Button as MuiButton, ButtonProps, FormLabel, FormLabelProps, styled } from '@mui/material'

export const Container = styled(Box)<BoxProps>({})

export const Button = styled(MuiButton)<ButtonProps>({
  border: 'none',
  background: '#00AB55',
  color: '#FFFFFF',
  fontSize: '10px',
  borderRadius: '5px',
  textTransform: 'inherit',
  boxShadow: '0px 3px 6px #00000029',
  '&:hover': {
    backgroundColor: '#0a542f',
    opacity: '0.9',
  },
})
export const Label = styled(FormLabel)<FormLabelProps>({
  borderBottom: 'none',
  fontSize: '0.8rem',
})

export const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
  borderRadius: '16px',
  p: 4,
}
