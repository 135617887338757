import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import { Autocomplete, AutocompleteProps, TextField, TextFieldProps, CircularProgress } from '@mui/material'

type IProps = {
  name: string
  defaultValue?: any | any[]
  textFieldProps: TextFieldProps
  isLoading?: boolean
}

type Props = IProps &
  Omit<AutocompleteProps<any, boolean | undefined, boolean | undefined, boolean | undefined>, 'renderInput'>

export default function RHFAutocomplete({
  name,
  defaultValue,
  textFieldProps,
  isLoading,
  onChange: providedOnChange,
  ...other
}: Props) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
        <Autocomplete
          {...rest}
          {...other}
          renderInput={(params) => (
            <TextField
              {...params}
              {...textFieldProps}
              error={!!error}
              helperText={error?.message}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          onChange={(...args) => {
            const [_, newValue] = args
            providedOnChange?.(...args)
            onChange(newValue)
          }}
        />
      )}
      defaultValue={defaultValue}
    />
  )
}
