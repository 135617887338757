import React from 'react'
import { useDropzone } from 'react-dropzone'

import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import { FileCard } from 'common/components/FileCard'
import Image from 'common/components/Image'
import { downloadFile } from 'common/util/downloadFile'

import BlockContent from './BlockContent'
import RejectionFiles from './RejectionFiles'
import { UploadProps } from './type'

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.grey[50],
  border: `1px dashed ${theme.palette.grey[400]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}))

export default function UploadSingleFile({
  error = false,
  file,
  helperText,
  sx,
  description,
  title,
  onRemoveFile,
  ...other
}: UploadProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other,
  })

  const isImage = /(.)(jpg|png|gif|bmp)/g.test(JSON.stringify(file))

  return (
    <Box sx={{ width: '100%', ...sx }}>
      {file && !isImage ? (
        <FileCard
          text={typeof file === 'string' ? file : file.path || ''}
          onCardContentClick={() => downloadFile(file)}
          onClick={onRemoveFile}
        />
      ) : (
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
            ...((isDragReject || error) && {
              color: 'error.main',
              borderColor: 'error.light',
              bgcolor: 'error.lighter',
            }),
            ...(file &&
              isImage && {
                padding: '12% 0',
              }),
          }}
        >
          <input {...getInputProps()} />

          <BlockContent title={title} description={description} />

          {file && isImage && (
            <Image
              alt='file preview'
              src={typeof file === 'string' ? file : file.preview}
              sx={{
                top: 8,
                left: 8,
                borderRadius: 1,
                position: 'absolute',
                width: 'calc(100% - 16px)',
                height: 'calc(100% - 16px)',
              }}
            />
          )}
        </DropZoneStyle>
      )}

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      {helperText && helperText}
    </Box>
  )
}
