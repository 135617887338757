/* eslint-disable no-undef */
import { useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import axios from 'axios'
import { deserialize } from 'deserialize-json-api'

import { notifyToast } from 'common/components/snackbar/Notify'

import { apiEndpoint } from '../config/apiEndpoint'
import { getHeaders } from '../helpers'
import listNotifyToast from '../util/listNotifyToast'
import { useInfo } from './useInfo'
import useInternalImpersonation from './useInternalImpersonation'
import { useLoadingState } from './useLoadingState'

const api = axios.create()

const handleKey = (key) => {
  const upperCasedKey = key.toUpperCase()

  // key is a "message"
  if (upperCasedKey === 'MESSAGE') return ''
  // key is a number on format "0"
  if (!isNaN(key)) return ''

  return `${upperCasedKey}: `
}

const mountMessage = (key, value) => handleKey(key) + value

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error?.response?.status === 401 &&
      !['/login', '/public'].some((item) => window.location.pathname.includes(item))
    ) {
      localStorage.removeItem('uid')
      localStorage.removeItem('token')
      localStorage.removeItem('Authorization')
      localStorage.removeItem('client')
      window.location.href = '/login'

      return listNotifyToast(null, error.response.data.errors, 'error', 1000)
    } else if (error.response?.status >= 400) {
      const { data } = error.response || {}
      if (data?.errors?.length) {
        return listNotifyToast(null, error.response.data.errors, 'error', 1000)
      }

      if (typeof data?.message === 'string') {
        return notifyToast(data?.message, 'error')
      }

      const hasMessage = !!Object.keys(data?.message).length
      const errors = hasMessage
        ? Object.entries(data.message).map(([key, value]) => mountMessage(key.toUpperCase(), value))
        : Object.entries(data || {})
            .map(([key, value]) =>
              Array.isArray(value) ? value.map((item) => mountMessage(key, item)) : mountMessage(key, value),
            )
            .flat()

      if (errors.length) {
        return listNotifyToast(null, errors, 'error', 1000)
      }
    }

    return Promise.reject(error)
  },
)

const formatParams = (currentLanguage, config, current_role) => ({
  params: {
    ...(current_role && { current_role }),
    ...(currentLanguage !== 'pt-BR' && { locale: currentLanguage }),
    ...(Boolean(config?.params) && { ...config.params }),
  },
  ...config,
})

const injectUrl = (url, inputs) => {
  const regex = /:\w*/g
  const newUrl = url.replace(regex, (match) => inputs[match.replace(':', '')])
  return `${apiEndpoint}${newUrl}`
}

export const useFetch = ({ path, method = 'GET', params = {} }) => {
  const { i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const { impersonationHeaders } = useInternalImpersonation()

  const [userInfo] = useInfo()

  const { role } = userInfo?.data || {}

  const call = useCallback(
    async ({
      body = {},
      query = {},
      overlapParams = {},
      source = {},
      controller = {},
      overWriteMethod = '',
      customHeaders = {},
    }) => {
      setIsLoading(true)

      const allParams = { ...params, ...overlapParams }

      Object.keys(allParams).forEach((key) => allParams[key] || delete allParams[key])

      const compositeUrl = `${apiEndpoint}${path.toUrl(allParams)}`

      const requestHeaders = impersonationHeaders || getHeaders()
      const localParamsFormatted = i18n.language !== 'pt-BR' ? { locale: i18n.language, ...query } : query

      try {
        const responseData = await api({
          method: overWriteMethod || method,
          url: compositeUrl,
          headers: {
            ...requestHeaders,
            ...customHeaders,
          },
          ...(Boolean(body) && {
            data: body,
          }),
          params: { ...localParamsFormatted, ...(role && { current_role: role }) },
          ...(source && { cancelToken: source.token }),
          ...(controller && { signal: controller.signal }),
        })

        if (responseData) {
          const deserializedData = deserialize(responseData.data)

          return Promise.resolve(deserializedData)
        }
      } catch (error) {
        if (error?.response?.data) {
          error.response.error = error.response.data
          delete error.response.data
        }

        throw new Error(error)
      } finally {
        setIsLoading(false)
      }
    },
    [method, params, path, role],
  )

  const methods = useMemo(() => ({ call, isLoading: isLoading === true }), [call, isLoading])

  return methods
}

export const useApi = (path, method = '', params = {}) => {
  const { i18n } = useTranslation()
  const [loading, setLoading, result, setResult] = useLoadingState()

  const { impersonationHeaders } = useInternalImpersonation()

  const [userInfo] = useInfo()
  const { role } = userInfo?.data || {}

  const call = async (body = {}, localParams = {}, overlapParams = {}, source = null, controller = {}) => {
    setLoading(() => true)

    const allParams = { ...params, ...overlapParams }
    for (let param in allParams) {
      allParams[param] || delete allParams[param]
    }

    const compositeUrl = `${apiEndpoint}${path.toUrl(allParams)}`
    const localParamsFormatted = i18n.language !== 'pt-BR' ? { locale: i18n.language, ...localParams } : localParams
    try {
      const response = await api({
        ...(source && { cancelToken: source.token }),
        ...(controller && { signal: controller.signal }),
        method: method || 'GET',
        url: compositeUrl,
        data: body,
        params: { ...localParamsFormatted, ...(role && { current_role: role }) },
        headers: impersonationHeaders || getHeaders(),
      })
      setResult(() => response)

      return Promise.resolve(response?.data)
    } catch (error) {
      if (error?.response?.data) {
        error.response.error = error.response.data
        delete error.response.data
      }
      setResult(() => error.response)
      return Promise.reject(error)
    } finally {
      setLoading(() => false)
    }
  }

  return [call, loading === true, result]
}

export const useGet = (url) => {
  const { i18n } = useTranslation()
  const [loading, setLoading, result, setResult] = useLoadingState()

  const [userInfo] = useInfo()
  const { role } = userInfo?.data || {}

  const { impersonationHeaders } = useInternalImpersonation()

  const call = async (params = {}) => {
    setLoading(() => true)

    const compositeUrl = `${apiEndpoint}${url}`

    try {
      const response = await axios.get(params && params.inputs ? injectUrl(url, params.inputs) : compositeUrl, {
        headers: impersonationHeaders || getHeaders(),
        ...formatParams(i18n.language, params.config || {}, role),
      })

      setResult(() => response)
      setLoading(() => false)
      return Promise.resolve(response?.data)
    } catch (error) {
      setLoading(() => false)
      process.env.NODE_ENV !== 'production' && console.warn(error)
      return Promise.reject('error')
    }
  }

  return [call, loading === true, result]
}

export const useDelete = (url) => {
  const { i18n } = useTranslation()
  const [loading, setLoading, result, setResult] = useLoadingState()

  const { impersonationHeaders } = useInternalImpersonation()

  const [userInfo] = useInfo()
  const { role } = userInfo?.data || {}

  const call = async (params = {}) => {
    setLoading(() => true)
    const compositeUrl = `${apiEndpoint}${url}`
    try {
      const response = await api.delete(params && params.inputs ? injectUrl(url, params.inputs) : compositeUrl, {
        headers: impersonationHeaders || getHeaders(),
        ...formatParams(i18n.language, params.config || {}, role),
      })

      setResult(() => response)
      setLoading(() => false)
      return response?.data
    } catch (error) {
      setLoading(() => false)
      setResult(error)
      process.env.NODE_ENV !== 'production' && console.warn(error)
      return undefined
    }
  }

  return [call, loading === true, result]
}

export const usePost = (url, opts = false) => {
  const { i18n } = useTranslation()
  const [loading, setLoading, result, setResult] = useLoadingState()

  const { impersonationHeaders } = useInternalImpersonation()

  const [userInfo] = useInfo()
  const { role } = userInfo?.data || {}

  const call = async ({ data = {}, config = {}, inputs = {} }) => {
    setLoading(() => true)

    const compositeUrl = `${apiEndpoint}${url}`
    try {
      const response = await api.post(inputs ? injectUrl(url, inputs) : compositeUrl, data, {
        headers: impersonationHeaders || getHeaders(),
        ...formatParams(i18n.language, config || {}, role),
      })

      setResult(() => response)
      setLoading(() => false)

      if (opts) return Promise.resolve(response)
      else return Promise.resolve({ ...response?.data, meta: response })
    } catch (error) {
      setLoading(() => false)
      setResult(() => error.response)
      process.env.NODE_ENV !== 'production' && Promise.reject(error)
    }
  }

  return [call, loading === true, result]
}

export const usePut = (url) => {
  const { i18n } = useTranslation()
  const [loading, setLoading, result, setResult] = useLoadingState()

  const { impersonationHeaders } = useInternalImpersonation()

  const [userInfo] = useInfo()
  const { role } = userInfo?.data || {}

  const call = async ({ data = {}, config = {}, inputs = {} }) => {
    setLoading(() => true)

    const compositeUrl = `${apiEndpoint}${url}`
    try {
      const response = await api.put(inputs ? injectUrl(url, inputs) : compositeUrl, data, {
        headers: impersonationHeaders || getHeaders(),
        ...formatParams(i18n.language, config || {}, role),
      })

      setResult(() => response)
      setLoading(() => false)

      return response?.data
    } catch (error) {
      setLoading(() => false)
      setResult(() => error.response)
      process.env.NODE_ENV !== 'production' && console.warn(error)
      return undefined
    }
  }

  return [call, loading === true, result]
}
