import React, { useMemo, useCallback, useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import DashboardIcon from '@mui/icons-material/Dashboard'
import EditIcon from '@mui/icons-material/Edit'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import PersonIcon from '@mui/icons-material/Person'
import { IconButton, Grow, CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { deleteFromStorage, useLocalStorage } from '@rehooks/local-storage'
import { t } from 'i18next'
import styled from 'styled-components'

import DefaultAvatar from 'common/assets/images/avatar.png'
import SingleBlox from 'common/assets/images/BloxBlock'
import BloxLogoImage from 'common/assets/images/BloxWhite.png'
import Dash from 'common/assets/images/Dash'
import FormationRules from 'common/assets/images/FormationRules'
import Gear from 'common/assets/images/Gear'
import List from 'common/assets/images/List'
import MeetingRoom from 'common/assets/images/meetingRoom'
import Paper from 'common/assets/images/Paper'
import PieChart from 'common/assets/images/PieChart'
import Pluzze from 'common/assets/images/Pluzze'
import TripleBlox from 'common/assets/images/TripleBlox'
import Divider from 'common/components/Divider'
import Dropdown from 'common/components/Dropdown'
import { SelectRole } from 'common/components/SelectRole'
import { notifyToast } from 'common/components/snackbar/Notify'
import { useApi } from 'common/hooks/useApi'
import { useInfo } from 'common/hooks/useInfo'
import { useThemeColor } from 'common/hooks/useThemeColor'
import useWindowDimensions from 'common/hooks/useWindowSize'
import { changeColor } from 'common/util/color'
import { filterPathsBy } from 'common/util/filterPaths'

import { TokensEndPoint } from 'routes/locations/endpoints/customLinks'
import { genneraEndpoint, myLibraryEndpoint } from 'routes/locations/endpoints/thirdPartyApps'
import { allowBloxCreation } from 'routes/secure/routeguards/permissions/allowBloxCreation'
import { allowBloxEdit } from 'routes/secure/routeguards/permissions/allowBloxEdit'
import { allowBloxList } from 'routes/secure/routeguards/permissions/allowBloxList'
import { allowBloxStatusEdit } from 'routes/secure/routeguards/permissions/allowBloxStatusEdit'
import { allowDashboard } from 'routes/secure/routeguards/permissions/allowDashboard'
import { allowDiscussionGroups } from 'routes/secure/routeguards/permissions/allowDiscussionGroups'
import { allowOfferings } from 'routes/secure/routeguards/permissions/allowOfferings'
import { allowStudentImpersonate } from 'routes/secure/routeguards/permissions/allowStudentImpersonate'
import { allowStudentsList } from 'routes/secure/routeguards/permissions/allowStudentsList'

import NotificationsIndicator from '../NotificationsIndicator'
import ImpersonateLabel from './ImpersonateLabel'

const iconStyles = makeStyles({
  root: {
    color: 'white',
  },
})

const BloxNavbar = ({ history, content: moreContent }) => {
  const [userInfo] = useInfo()
  const { color } = useThemeColor()
  const [permissions, setPermissions] = useState([])
  const [course, setCourse] = useState(null)
  const impersonateControlsRef = useRef(null)
  const [userImpersonating] = useLocalStorage('user-impersonating')
  const [isImpersonating] = useLocalStorage('isImpersonating')
  const { t } = useTranslation()

  const [getToken] = useApi(TokensEndPoint, 'POST')

  const { width } = useWindowDimensions()
  const mobile = width < 850

  const header_configuration = useMemo(() => {
    const { header_configuration: institutionConfiguration } = userInfo?.data ?? {}

    if (institutionConfiguration) {
      return {
        ...institutionConfiguration,
        ...((!institutionConfiguration.custom_color || !institutionConfiguration.color) && { color }),
        ...(!institutionConfiguration.custom_background && { background_categories: ['nature', 'water'] }),
      }
    }
    return {
      custom_color: true,
      color: color,
      custom_background: false,
      background_categories: ['water', 'landscape'],
    }
  }, [userInfo, color])

  useEffect(() => {
    if (impersonateControlsRef && impersonateControlsRef.current) {
      document.querySelector('.teste').style.paddingTop = `${impersonateControlsRef.current.offsetHeight}px`
    }
  }, [impersonateControlsRef])

  const currentUserRole =
    userInfo.data.role.includes('student') && userInfo.data.ecommerce_user ? 'ecommerce-student' : userInfo.data.role

  useEffect(() => {
    if (currentUserRole == 'admin') {
      getToken()
    }
  }, [])

  const mappedCustomLinks = useCallback(
    (role) => {
      if (userInfo.data.custom_links.length) {
        const customLink = userInfo.data.custom_links.filter((link) => link.profiles?.includes(role))

        if (customLink.length) {
          return customLink.map((link) => {
            const url = /^https:\/\//.test(link.url) ? link.url : `https://${link.url}`
            return {
              path: link.is_external ? url : link.url,
              text: link.title,
              icon: SingleBlox,
              external: link.is_external,
            }
          })
        }
      }
      return []
    },
    [userInfo],
  )

  const links = useMemo(() => {
    const thirdPartyApps = {
      minha_biblioteca: {
        text: 'Minha Biblioteca',
        path: 'myLibrary',
        icon: SingleBlox,
        external: true,
      },
      gennera: {
        text: 'Gennera',
        path: 'gennera',
        icon: SingleBlox,
        external: true,
      },
    }

    const permittedThirdPartyApps = []
    userInfo?.data?.institution?.enabled_third_party_apps?.forEach((app) => {
      if (thirdPartyApps[app]) {
        permittedThirdPartyApps.push(thirdPartyApps[app])
      }
    })

    return {
      student: [
        { path: '/student', exactPath: true, text: t('blox-history:grid'), icon: TripleBlox },
        {
          path: '/student/formation-rules',
          active: ['/student/formation-rules'],
          text: t('blox-history:formationRules'),
          icon: FormationRules,
        },
        {
          path: '/student/enrollments',
          active: ['/student/enrollments'],
          text: t('blox-history:mountGrid'),
          icon: SingleBlox,
        },
        { path: '/student/history', active: ['/student/history'], text: t('blox-history:history'), icon: Paper },
        {
          path: '/student/evolution',
          active: ['/student/evolution'],
          text: t('blox-history:evolution'),
          icon: PieChart,
        },
        ...permittedThirdPartyApps,
        ...(userInfo.data?.institution?.settings?.cometchat?.active
          ? [
              {
                path: '/student/discussion-groups',
                active: ['/student/discussion-groups'],
                text: t('blox-history:groups'),
                icon: MeetingRoom,
              },
            ]
          : []),
        ...mappedCustomLinks('student'),
      ],
      ecommerceStudent: [
        { path: '/ecommerce-student', exactPath: true, text: t('blox-history:myCourseUnits'), icon: TripleBlox },

        {
          path: '/ecommerce-student/history',
          active: ['/ecommerce-student/history'],
          text: t('blox-history:history'),
          icon: Paper,
        },
        {
          path: '/ecommerce-student/evolution',
          active: ['/ecommerce-student/evolution'],
          text: t('blox-history:evolution'),
          icon: PieChart,
        },
        ...(userInfo.data?.institution?.settings?.cometchat?.active
          ? [
              {
                path: '/ecommerce-student/discussion-groups',
                active: ['/ecommerce-student/discussion-groups'],
                text: t('blox-history:groups'),
                icon: MeetingRoom,
              },
            ]
          : []),
        {
          path: '/ecommerce-student/complementary-formation',
          active: ['/ecommerce-student/complementary-formation'],
          text: t('blox-history:complementaryTraining'),
          icon: FormationRules,
        },
        {
          path: `/public/${userInfo.data?.institution?.id}/offerings`,
          active: [`/public/${userInfo.data?.institution?.id}/offerings`],
          text: t('blox-history:buyMore'),
          icon: SingleBlox,
        },
      ],
      professor: [
        {
          path: '/professor',
          exactPath: true,
          text: t('professorMenu:classes'),
          icon: TripleBlox,
        },
        ...(permissions.includes('bloxCreation')
          ? [
              {
                path: '/coordinator/blox-creation',
                active: ['/coordinator/blox-creation'],
                text: t('professorMenu:newBlox'),
                icon: SingleBlox,
              },
            ]
          : []),
        ...(permissions.includes('bloxList')
          ? [
              {
                path: '/professor/blox-list',
                active: ['/professor/blox-list'],
                text: t('professorMenu:blox'),
                icon: List,
              },
            ]
          : []),
        ...(userInfo.data?.institution?.settings?.cometchat?.active && permissions.includes('discussionGroups')
          ? [
              {
                path: '/professor/discussion-groups',
                active: ['/professor/discussion-groups'],
                text: t('professorMenu:discussion'),
                icon: MeetingRoom,
              },
            ]
          : []),
      ],
      coordinator: [
        {
          path: '/coordinator',
          exactPath: true,
          text: 'Dashboard',
          icon: DashboardIcon,
        },
        ...(permissions.includes('offerings')
          ? [
              {
                path: '/coordinator/offerings',
                active: ['/coordinator/offerings'],
                text: t('offerings:offers'),
                icon: TripleBlox,
              },
            ]
          : []),
        ...(permissions.includes('bloxList')
          ? [
              {
                path: '/coordinator/blox-list',
                active: ['/coordinator/blox-list'],
                text: t('offerings:curricular'),
                icon: SingleBlox,
              },
            ]
          : []),
        ...(permissions.includes('studentsList')
          ? [
              {
                path: '/coordinator/students',
                active: ['/coordinator/students', '/coordinator/students/edit/'],
                text: t('offerings:students'),
                icon: PersonIcon,
              },
            ]
          : []),
      ],
      admin: [
        {
          path: '/admin',
          exactPath: true,
          active: ['/admin'],
          text: 'Dashboard',
          icon: Dash,
        },
        {
          path: '/admin/users',
          active: ['/admin/users'],
          text: t('area-descriptions:user'),
          icon: PersonIcon,
        },
        {
          path: '/admin/pedagogical-parameters',
          active: ['/admin/pedagogical-parameters'],
          text: t('area-descriptions:pedagogical'),
          icon: Pluzze,
        },
        {
          path: '/admin/general-parameters',
          active: ['/admin/general-parameters'],
          text: t('area-descriptions:parameters'),
          icon: Gear,
        },
      ],
    }
  }, [userInfo, t, permissions])

  const classesIcon = iconStyles()

  const roleLinks = () => {
    const role =
      userInfo.data.role.includes('student') && userInfo.data.ecommerce_user ? 'ecommerceStudent' : userInfo.data.role

    const linksList = links[role] && links[role].length && links[role]
    return filterPathsBy(linksList, [
      {
        condition: userInfo.data.current_course == null,
        excludedPaths: ['/student/formation-rules', '/student/enrollments', '/ecommerce-student/discussion-groups'],
      },
    ])
  }

  const logout = () => {
    const redirectTo = userInfo.data.institution.options.logout_redirect_to

    deleteFromStorage('token')
    deleteFromStorage('Authorization')
    deleteFromStorage('client')
    deleteFromStorage('uid')
    deleteFromStorage('userInfo')
    deleteFromStorage('user-impersonating-headers')
    deleteFromStorage('user-impersonating')
    deleteFromStorage('isImpersonating')

    if (redirectTo !== undefined) {
      window.location.href = redirectTo
    } else {
      history.push('/')
    }
  }

  useEffect(() => {
    const call = async () => {
      const dashboard = await allowDashboard()
      const bloxCreation = await allowBloxCreation()
      const bloxEdit = await allowBloxEdit()
      const bloxList = await allowBloxList()
      const bloxStatusEdit = await allowBloxStatusEdit()
      const discussionGroups = await allowDiscussionGroups()
      const offerings = await allowOfferings()
      const studentImpersonate = await allowStudentImpersonate()
      const studentsList = await allowStudentsList()

      const final = [
        ...(dashboard ? ['dashboard'] : []),
        ...(bloxCreation ? ['bloxCreation'] : []),
        ...(bloxEdit ? ['bloxEdit'] : []),
        ...(bloxList ? ['bloxList'] : []),
        ...(bloxStatusEdit ? ['bloxStatusEdit'] : []),
        ...(discussionGroups ? ['discussionGroups'] : []),
        ...(offerings ? ['offerings'] : []),
        ...(studentImpersonate ? ['studentImpersonate'] : []),
        ...(studentsList ? ['studentsList'] : []),
      ]

      setPermissions(final)
    }
    call()
  }, [])

  return (
    <>
      {isImpersonating && userImpersonating?.data && (
        <ImpersonateContainer ref={impersonateControlsRef}>
          <ImpersonateLabel />
        </ImpersonateContainer>
      )}
      <BackgroundContainer
        headerconfig={header_configuration}
        themes={header_configuration.background_categories}
        background={header_configuration.color}
        className='teste'
        hasMoreContent={!!moreContent}
      >
        <HeaderContainer
          style={{
            paddingTop: impersonateControlsRef.current ? `${impersonateControlsRef.current.offsetHeight}px` : '0',
          }}
        >
          <UserInfoContainer>
            <AvatarContainer>
              {['student', 'ecommerce-student', 'professor'].includes(currentUserRole) && (
                <ChangeImage onClick={() => history.push(`/${currentUserRole}/settings`)}>
                  <EditIcon />
                  {t('dashboard:edit')}
                </ChangeImage>
              )}
              <AvatarImg src={userInfo.data.picture_url || DefaultAvatar} alt='avatar' />
            </AvatarContainer>
            <UserDescription>
              <Row>
                <div style={{ width: '100%' }}>
                  <h4>{t('dashboard:hello')}</h4>
                  <NameTitle>{userInfo.data.name.toLowerCase()}</NameTitle>
                </div>
                {!mobile && <NotificationsIndicator color='white' />}
              </Row>
              <Divider />
              {userInfo?.data?.courses.length ? (
                <>
                  {userInfo.data.courses.length === 1 ? (
                    <Text>{userInfo.data.courses[0].name}</Text>
                  ) : (
                    <Dropdown
                      title={course || userInfo.data.courses[0].name}
                      list={userInfo.data.courses.map((course) => ({
                        title: course.name,
                        onClick: () => {
                          setCourse(course.name)
                        },
                      }))}
                    />
                  )}
                </>
              ) : (
                ''
              )}
            </UserDescription>
            {userInfo?.data?.roles?.length > 1 && <SelectRole userInfo={userInfo} />}
          </UserInfoContainer>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              alignSelf: 'baseline',
              width: mobile ? 'calc(100% - 40px)' : 'auto',
              padding: '20px 20px 7px',
            }}
          >
            {mobile ? (
              <InstituitionContainer>
                <img
                  style={{ marginRight: 20, width: 'unset', height: 75, objectFit: 'contain' }}
                  src={userInfo.data.institution.logo_url || BloxLogoImage}
                  alt='institutionLogo'
                />
                <div
                  style={{
                    display: 'flex',
                    marginRight: 20,
                  }}
                >
                  <NotificationsIndicator color='white' />
                  <div>
                    <IconButton onClick={logout}>
                      <ExitToAppIcon classes={classesIcon} />
                    </IconButton>
                  </div>
                </div>
              </InstituitionContainer>
            ) : (
              <>
                <img
                  style={{
                    marginRight: 20,
                    height: 75,
                    objectFit: 'contain',
                  }}
                  src={userInfo.data.institution.logo_url || BloxLogoImage}
                  alt='institutionLogo'
                />

                <IconButton onClick={logout}>
                  <ExitToAppIcon classes={classesIcon} />
                </IconButton>
              </>
            )}
          </div>
        </HeaderContainer>

        {/* change color props for navbar color changes */}
        <NavigationContainer>
          {roleLinks().map((link, index) => (
            <NavigationLink
              key={link.text}
              color={color}
              url={link.path}
              activeLink={link.active}
              exactPath={link.exactPath}
              text={link.text}
              icon={link.icon || 'pallette'}
              external={link.external}
              index={index}
            />
          ))}
        </NavigationContainer>
        {moreContent && <ContentContainer>{moreContent}</ContentContainer>}
      </BackgroundContainer>
    </>
  )
}

const NavigationLink = withRouter(
  ({ history, location, url, activeLink, exactPath, icon, text, color, index, external }) => {
    const [indexFetching, setIndexFetching] = useState(null)
    const [userInfo] = useInfo()

    const [postMyLibrary, loadingMyLibrary] = useApi(myLibraryEndpoint, 'POST')
    const [postGennera, loadingGennera] = useApi(genneraEndpoint, 'POST')

    const active = exactPath
      ? location.pathname === url
      : activeLink && activeLink.some((item) => location.pathname.startsWith(url) && url.startsWith(item))

    const handleCustomLink = async () => {
      try {
        if (url) {
          if (!external) {
            return (window.location.href = url)
          }

          if (userInfo?.data?.institution_id === 49 && userInfo?.data?.role === 'student') {
            setIndexFetching(index)
            const thirdPartyNavigation = {
              myLibrary: postMyLibrary,
              gennera: postGennera,
            }

            const thirdPartyMethod = thirdPartyNavigation[url]

            const { redirect_url } = await thirdPartyMethod()

            return window.open(redirect_url, '_blank')
          } else {
            return window.open(url, '_blank')
          }
        }
      } catch {
        notifyToast(t('blox-history:cannotGetExternalLink'), 'error')
      } finally {
        setIndexFetching(null)
      }
    }

    const Icon = icon
    return (
      <Grow in timeout={(1000 * index) / 2}>
        <NavigationLinkTile
          onClick={() => (external ? handleCustomLink() : history.push(url))}
          background={active ? color : 'white'}
          color={active ? 'white' : color}
          index={index}
        >
          {(loadingMyLibrary || loadingGennera) && indexFetching === index ? (
            <CircularProgress size={24} style={{ marginTop: 20 }} />
          ) : (
            <Icon width='25' style={{ marginTop: 20 }} />
          )}

          <h2
            style={{
              color: active ? 'white' : 'black',
              fontSize: 16,
              marginTop: '10px',
              fontWeight: 350,
            }}
          >
            {text}
          </h2>
        </NavigationLinkTile>
      </Grow>
    )
  },
)

const ImpersonateContainer = styled.div`
  position: fixed;
  width: 100%;
  z-index: 99999;

  @media print {
    display: none;
  }
`
const NameTitle = styled.h2`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ContentContainer = styled.div`
  padding: 65px 70px 0 70px;
  @media only screen and (max-width: 850px) {
    padding: 90px 30px 0 30px;
  }
`

const InstituitionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`
const UserInfoContainer = styled.div`
  width: calc(100% - 40px);
  display: flex;
  margin: 20px 20px 7px;

  @media (max-width: 468px) {
    flex-direction: column;
  }
`
const NavigationLinkTile = styled.div`
  position: relative;
  background-color: ${({ background }) => (background ? changeColor({ color: background }) : 'white')};
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 30px;
  margin-left: 40px;
  margin: 15px;
  width: 100%;
  max-width: 225px;
  height: 100px;
  padding: 0 20px;
  cursor: pointer;

  path {
    fill: ${(props) => (props.color ? props.color : 'black')};
  }

  @media only screen and (max-width: 850px) {
    min-width: 100px;
    min-height: 140px;
    max-width: 100px;
    max-height: 140px;
    margin-left: ${(props) => (props.index !== 0 ? '15px' : '0px')};
  }
`

const BackgroundContainer = styled.div`
  ${({ headerconfig }) =>
    headerconfig.custom_background
      ? `background: linear-gradient(${changeColor({ color: headerconfig.color, opacity: 0.75 })}, ${changeColor({
          color: headerconfig.color,
          opacity: 0.75,
        })}), url('https://source.unsplash.com/1600x900/?${headerconfig.background_categories.join(
          ',',
        )}') no-repeat center center`
      : `background-color: ${headerconfig.color}`};
  background-size: cover;
  background-position: top center;
  transition: background-color 1s ease;
  @media only screen and (max-width: 850px) {
    width: 100%;
  }

  @media print {
    display: none;
  }
`
const HeaderContainer = styled.div`
  padding: 30px 50px;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 850px) {
    padding: 0;
    flex-direction: column-reverse;
  }
`

const NavigationContainer = styled.div`
  @media only screen and (max-width: 850px) {
    overflow-x: scroll;
    padding: 30px 20px 19px 20px;
    width: calc(100% - 40px);
  }
  width: calc(100% 100px);
  z-index: 997;
  display: flex;
  user-select: none;
  flex-wrap: nowrap;
  padding: 0 50px;
  margin-top: -90px;
  transform: translateY(50%);
`
const UserDescription = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 91px);

  h2 {
    margin: 0;
    font-weight: normal;
    color: ${(props) => (props.color ? props.color : 'white')};
    text-transform: capitalize;
  }

  h4 {
    text-transform: capitalize;
    font-weight: normal;
    color: ${(props) => (props.color ? props.color : 'white')};
  }
`
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const AvatarContainer = styled.div`
  position: relative;
`

const ChangeImage = styled.div`
  cursor: pointer;
  flex-direction: column;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  width: 75px;
  height: 75px;
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.5;
  }
`
const AvatarImg = styled.img`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin-right: 16px;
  object-fit: cover;
  object-position: center right;
`

const Text = styled.p`
  font-size: 12px;
  color: white;
`

export default connect((store) => ({ content: store.content }))(withRouter(BloxNavbar))
