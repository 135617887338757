import React from 'react'
import { useTranslation } from 'react-i18next'

import axios from 'axios'

import { getHeaders } from '../helpers'

export const useRetrieve = (resource: any, relation: string, urlParams: any, lazy?: boolean, generalParams?: any) => {
  const { i18n } = useTranslation()
  const [response, setResponse] = React.useState<any>(undefined)
  const [error, setError] = React.useState<any>(undefined)
  const [meta, setMeta] = React.useState<any>(undefined)
  const [loading, setLoading] = React.useState<boolean>(false)

  const get = React.useMemo(() => {
    const call = async (localUrlParams: any, params: any) => {
      try {
        setLoading(() => true)
        const fullParams = { ...urlParams, ...localUrlParams }
        const formattedParams =
          i18n.language !== 'pt-BR'
            ? { locale: i18n.language, ...params, ...generalParams }
            : { ...params, ...generalParams }

        const response = await axios.get(
          relation === 'many' ? resource.listUrl(fullParams) : resource.url(fullParams),
          { params: formattedParams, headers: getHeaders() },
        )

        setResponse(() => response.data)
        setMeta(() => response)

        setLoading(() => false)

        return response.data
      } catch (err: any) {
        setError(() => err)
        setMeta(() => ({ ...err.response }))

        setLoading(() => false)
      }
    }
    return call
  }, [urlParams, relation])

  React.useEffect(() => {
    if (lazy) return
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    get()
  }, [])

  const hookValue = React.useMemo(() => {
    return [get, loading, response, meta, error]
  }, [loading, response, error, meta, get])

  return hookValue
}

export const useMutation = (resource: any, method: string, urlParams: any, callback?: VoidFunction) => {
  const { i18n } = useTranslation()
  const [response, setResponse] = React.useState<any>(undefined)
  const [error, setError] = React.useState<any>(undefined)
  const [meta, setMeta] = React.useState<any>(undefined)

  const [loading, setLoading] = React.useState(false)

  const mutate: (body: any, localUrlParams?: any, config?: any) => Promise<any> = React.useMemo(() => {
    const call = async (body: any, localUrlParams: any, config: any) => {
      try {
        setLoading(() => true)
        const fullParams = { ...urlParams, ...localUrlParams }
        const formattedConfig = {
          params: {
            ...(i18n.language !== 'pt-BR' && { locale: i18n.language }),
            ...(Boolean(config?.params) && { ...config.params }),
          },
          ...config,
        }

        const response = await axios({
          url: method === 'POST' ? resource.listUrl(fullParams) : resource.url(fullParams),
          method,
          data: body,
          headers: getHeaders(),
          ...formattedConfig,
        })

        setResponse(() => response.data)
        setMeta(() => response)
        setLoading(() => false)

        callback && callback()
        const { data, ...rest } = response
        return Promise.resolve({ ...data, meta: { ...rest } })
      } catch (err: any) {
        setError(() => err)
        setMeta(() => err.response)
        setLoading(() => false)

        throw err.response
      }
    }
    return call
  }, [])

  const hookValue = React.useMemo(() => {
    return [mutate, loading, response, error, meta]
  }, [loading, response, error, mutate, meta])

  return hookValue
}
