import React from 'react'
const Gear = (props) => (
  <svg {...props} xmlns='http://www.w3.org/2000/svg' width='28.158' height='28.043' viewBox='0 0 28.158 28.043'>
    <g transform='translate(0 -0.114)'>
      <g transform='translate(0 8.061)'>
        <path
          className='a'
          d='M18.516,26.661a8.428,8.428,0,0,0-.054-2.038l1.543-.688a10.165,10.165,0,0,0-1.863-4.184l-1.544.687a8.456,8.456,0,0,0-1.478-1.4l.605-1.577a10.21,10.21,0,0,0-4.276-1.643l-.606,1.579a8.428,8.428,0,0,0-2.038.054l-.684-1.536a10.2,10.2,0,0,0-4.18,1.874l.679,1.525a8.442,8.442,0,0,0-1.4,1.478l-1.573-.6A10.028,10.028,0,0,0,.575,22.23,10.323,10.323,0,0,0,0,24.461l1.577.605A8.428,8.428,0,0,0,1.631,27.1l-1.543.687a10.157,10.157,0,0,0,1.864,4.184l1.543-.687a8.426,8.426,0,0,0,1.479,1.4l-.605,1.578a10.21,10.21,0,0,0,4.276,1.643l.606-1.579a8.454,8.454,0,0,0,2.038-.054l.684,1.535a10.2,10.2,0,0,0,4.181-1.874l-.68-1.525a8.443,8.443,0,0,0,1.4-1.478l1.572.6A10.037,10.037,0,0,0,19.517,29.5a10.213,10.213,0,0,0,.576-2.232Zm-4.192.844a4.581,4.581,0,1,1-2.636-5.918A4.581,4.581,0,0,1,14.323,27.5Z'
          transform='translate(0 -15.816)'
        />
      </g>
      <g transform='translate(16.147 0.114)'>
        <path
          className='a'
          d='M42.816,6.33a4.926,4.926,0,0,0-.085-.9l1.183-.683a6.208,6.208,0,0,0-1.633-2.835L41.095,2.6a4.873,4.873,0,0,0-1.55-.894V.339a6.053,6.053,0,0,0-3.272,0V1.708a4.888,4.888,0,0,0-1.55.894l-1.186-.685A6.222,6.222,0,0,0,31.9,4.752l1.183.683a4.729,4.729,0,0,0,0,1.791L31.9,7.909a6.219,6.219,0,0,0,1.633,2.835l1.186-.685a4.879,4.879,0,0,0,1.55.894v1.369a6.053,6.053,0,0,0,3.272,0V10.953a4.883,4.883,0,0,0,1.55-.894l1.186.685a6.208,6.208,0,0,0,1.633-2.835l-1.183-.683A4.891,4.891,0,0,0,42.816,6.33ZM37.909,8.621A2.29,2.29,0,1,1,40.2,6.33,2.291,2.291,0,0,1,37.909,8.621Z'
          transform='translate(-31.903 -0.114)'
        />
      </g>
    </g>
  </svg>
)
export default Gear
