import { prepareDates, mergeEvents } from './'

export const createEventsArray = (events, currentRange = []) => {
  const availableIDs = []
  const [rangeStart] = currentRange

  const daysEvents = events.reduce((daysAcc, dayEvents) => {
    const [day, schedules] = Object.entries(dayEvents)[0]

    const schedulesEvents = schedules.map((scheduleEvent) => {
      if (scheduleEvent?.available?.length || scheduleEvent.blox_offering?.id) {
        const { start, end } = prepareDates({
          day,
          start: scheduleEvent.schedule.start,
          end: scheduleEvent.schedule.end,
          rangeStart,
        })

        scheduleEvent.available && availableIDs.push(...scheduleEvent.available)

        return {
          type: scheduleEvent?.blox_offering?.blox?.id ? 'appointment' : 'slot',
          ...scheduleEvent.blox_offering,
          params: scheduleEvent.params,
          start,
          end,
          status: scheduleEvent.blox_enrollment_status,
          observation: scheduleEvent.blox_offering.observation,
        }
      }
      return false
    })

    return [...daysAcc, ...schedulesEvents]
  }, [])

  const appointments = daysEvents.filter((event) => event.type === 'appointment')
  const mergedAppointments = mergeEvents(appointments)
  const slots = daysEvents
    .filter((event) => ['appointment', 'slot'].includes(event.type))
    .map((slot) => ({
      type: 'slot',
      params: slot.params,
      start: slot.start,
      end: slot.end,
    }))

  return { appointments: mergedAppointments, slots, availableIDs: [...new Set(availableIDs)] }
}

export const createCalendarEventsArray = (events, type) => {
  let appointments = []

  Object.values(events).forEach((event) =>
    event.forEach((item) => {
      const { start, end } = item.schedule

      const schedule = {
        ...item,
        start: new Date(start),
        end: new Date(end),
        type,
      }

      return appointments.push(schedule)
    }),
  )

  return appointments
}
