import React, { Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Provider } from 'react-redux'
import { withRouter } from 'react-router'
import ReactTooltip from 'react-tooltip'

import { ThemeProvider, StyledEngineProvider, createTheme as createMuiTheme } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import enUS from 'date-fns/locale/en-US'
import ptBR from 'date-fns/locale/pt-BR'
import { generalLinks } from 'modules/admin/general-parameters/links'
import { pedagogicalLinks } from 'modules/admin/pedagogical-parameters/links'
import { ThemeProvider as StyledProvider } from 'styled-components'

import Footer from 'common/components/Footer'
import AdminSidebar, { ResponsiveContainer } from 'common/components/navbar/AdminSidebar'
import BloxNavbar from 'common/components/navbar/BloxNavbar'
import MainNavbar from 'common/components/navbar/MainNavbar'
import { useDisclose } from 'common/hooks/useDisclose'
import { useInfo } from 'common/hooks/useInfo'
import { useThemeColor } from 'common/hooks/useThemeColor'
import store from 'common/store'

import { ModalForm } from './login/form/component/ModalForm'
import 'moment/locale/pt-br'

const Routes = React.lazy(() => import('routes'))

const App = () => {
  const [userInfo] = useInfo(),
    { color } = useThemeColor(),
    { i18n } = useTranslation()
  const { isOpen: openModal, onToggle: toggleOpenModal } = useDisclose()

  const { external_data, roles, institution } = userInfo?.data || {}

  const currentYear = new Intl.DateTimeFormat('pt-BR', { year: 'numeric' }).format(new Date())

  const theme = createMuiTheme({
    palette: {
      primary: { main: color },
      secondary: {
        main: '#366BC7',
        contrastText: '#fff',
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 993,
        lg: 1280,
        xl: 1920,
      },
    },
    typography: {
      useNextVariants: true,
    },
    components: {
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
          sx: {
            '.MuiOutlinedInput-root': { borderRadius: 2 },
            '& .MuiOutlinedInput-notchedOutline': {
              width: 'auto',
              legend: { display: 'inline-flex', borderBottom: 0 },
            },
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: 'standard',
        },
      },
    },
  })

  useEffect(() => {
    if (
      !openModal &&
      roles?.includes('professor') &&
      institution?.id === 53 &&
      Object.keys(external_data || {}).length <= 1
    ) {
      toggleOpenModal()
    }
  }, [roles, institution, external_data])

  const excludePaths = [
    '/public',
    '/validate_certificate',
    '/student/validate_certificate/',
    '/coordinator/lecture-items',
    '/student/lecture-items',
    '/student/assessment',
    '/class/',
    '/login',
  ]

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={i18n.language.toLowerCase() === 'pt-br' ? ptBR : enUS}
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <StyledProvider theme={theme}>
            <Provider store={store}>
              {!excludePaths.some((path) => window.location.pathname.includes(path)) && (
                <>
                  {['student', 'professor', 'coordinator', 'admin'].includes(userInfo?.data?.role) ? (
                    <BloxNavbar />
                  ) : (
                    <MainNavbar />
                  )}
                </>
              )}
              <Suspense>
                {userInfo?.data?.role === 'admin' ? (
                  window.location.pathname.includes('/admin/general-parameters') ||
                  window.location.pathname.includes('/admin/pedagogical-parameters') ? (
                    <ResponsiveContainer>
                      <AdminSidebar
                        links={
                          window.location.pathname.includes('/admin/general-parameters')
                            ? generalLinks
                            : pedagogicalLinks
                        }
                      />
                      <Routes />
                    </ResponsiveContainer>
                  ) : (
                    <Routes />
                  )
                ) : (
                  <Routes />
                )}
              </Suspense>
              {!excludePaths.some((path) => window.location.pathname.includes(path)) && <Footer year={currentYear} />}
              <ReactTooltip className='tooltip-extra-class' effect='float' multiline />
              <ModalForm open={openModal} toggleOpenModal={toggleOpenModal} />
            </Provider>
          </StyledProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  )
}
export default withRouter(App)
