import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import { Checkbox, FormControlLabel, FormGroup, FormControlLabelProps } from '@mui/material'

interface RHFCheckboxProps extends Omit<FormControlLabelProps, 'control'> {
  name: string
  disabled?: boolean
}

export function RHFCheckbox({ name, disabled, ...other }: RHFCheckboxProps) {
  const { control } = useFormContext()

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => <Checkbox {...field} checked={field.value} disabled={disabled} />}
        />
      }
      {...other}
    />
  )
}

interface RHFMultiCheckboxProps extends Omit<FormControlLabelProps, 'control' | 'label'> {
  name: string
  disabled: boolean
  options: {
    disabled: boolean | undefined
    label: string
    value: any
  }[]
}

export function RHFMultiCheckbox({ name, options, ...other }: RHFMultiCheckboxProps) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const onSelected = (option: string) =>
          field.value.includes(option)
            ? field.value.filter((value: string) => value !== option)
            : [...field.value, option]

        return (
          <FormGroup>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={field.value.includes(option.value)}
                    onChange={() => field.onChange(onSelected(option.value))}
                  />
                }
                label={option.label}
                {...other}
              />
            ))}
          </FormGroup>
        )
      }}
    />
  )
}
