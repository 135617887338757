import { styled, ButtonGroup as MaterialButtonGroup, Select as MaterialSelect } from '@mui/material'

const ButtonGroup = styled(MaterialButtonGroup)(({ theme: { shape } }) => ({
  minWidth: 100,
  borderRadius: shape.borderRadius * 2,
  boxShadow: 'none',
  '& .MuiAutocomplete-root': {
    '& fieldset': { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
  },
  '& > :nth-of-type(1)': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  '& > :nth-of-type(2)': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderRadius: 'inherit',
    '& fieldset': { borderTopLeftRadius: 0, borderBottomLeftRadius: 0, width: 'auto' },
  },
  '& > *': {
    '&:before': { borderBottom: 'none !important' },
  },
}))

const Select = styled(MaterialSelect<string>)(({ theme: { palette }, placeholder, value }) => ({
  height: 'auto',
  borderRadius: 'inherit',
  backgroundColor: palette.primary.main,
  color: palette.primary.contrastText,
  '&:before': { borderBottom: 'unset !import' },
  '&:after': { borderBottom: 'unset !import' },
  '&:hover': {
    '& fieldset': {
      border: 'unset !important',
    },
  },
  '&:hover:not(.Mui-disabled, .Mui-error):before': { borderBottom: 'unset' },
  '& .MuiBox-root': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 0.5,
    ...(value ? { paddingInline: '0.5rem' } : {}),
  },
  '& .MuiChip-root': { borderRadius: '0.25rem' },
  '& svg': { color: 'currentColor' },
  '& div[role="button"]': {
    ...(!value && placeholder && { minWidth: `${placeholder.length * 0.55}rem !important` }),
    height: '-webkit-fill-available',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .notranslate::after': { ...(!value && { content: `"${placeholder}"`, opacity: 0.6 }) },
  },
  '& fieldset': { boxSizing: 'border-box' },
}))

export { ButtonGroup, Select }
