interface CustomFile extends File {
  path?: string
  preview?: string
  lastModifiedDate?: Date
  url?: string
}

export const downloadFile = (file: string | CustomFile) => {
  if (typeof file !== 'string' && (file?.preview || file?.url)) {
    window.open(file?.preview || file?.url, '_blank')
  }
}
