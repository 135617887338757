import React, { useState, forwardRef } from 'react'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { TextField, InputAdornment, IconButton } from '@mui/material'

import { useStyles } from './styles'

export const PasswordInput = forwardRef(({ height = null, name, ...props }, ref) => {
  const classes = useStyles()
  const [showPassword, setShowPassword] = useState(false)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleClickShowPassword = () => {
    setShowPassword((oldState) => !oldState)
  }

  return (
    <TextField
      ref={ref}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        name,
        endAdornment: (
          <InputAdornment onMouseDown={handleMouseDownPassword} onClick={handleClickShowPassword}>
            <IconButton size='large'>{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
          </InputAdornment>
        ),
        classes: { notchedOutline: classes.widthAuto },
        ...(height && { sx: { height } }),
      }}
      {...props}
    />
  )
})
