import React from 'react'

import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { Typography, CardContent, Card, Stack, IconButton, IconButtonProps } from '@mui/material'

import { Icon } from 'common/components/File'
import { useDisclose } from 'common/hooks/useDisclose'

type CommonProps = Pick<IconButtonProps, 'onClick'>

interface IFileCard extends CommonProps {
  text: string
  onCardContentClick: VoidFunction
  hideDeleteButton?: boolean
}

export const FileCard = ({ text, onCardContentClick, onClick, hideDeleteButton = true }: IFileCard) => {
  const { isOpen: isRaised, onOpen, onClose } = useDisclose()

  return (
    <Card raised={isRaised} sx={{ position: 'relative' }}>
      {hideDeleteButton && (
        <IconButton color='error' onClick={onClick} sx={{ position: 'absolute', top: 4, right: 4 }}>
          <HighlightOffIcon />
        </IconButton>
      )}

      <CardContent onClick={onCardContentClick} onMouseOver={onOpen} onMouseLeave={onClose} sx={{ cursor: 'pointer' }}>
        <Stack direction='row' alignItems='center' spacing={2}>
          <Icon />
          <Typography
            variant='body1'
            color={isRaised ? 'blue' : 'GrayText'}
            textAlign='center'
            sx={{ lineBreak: 'anywhere', transition: 'color 0.2s ease-in-out' }}
          >
            {text}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  )
}
