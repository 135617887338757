import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

import { deleteFromStorage, useLocalStorage, writeStorage } from '@rehooks/local-storage'
import styled from 'styled-components'

import ActionButtons from '../../../common/components/buttons/ActionButtons'
import BinocularImage from '../../assets/images/binocular.svg'
import { useInfo } from '../../hooks/useInfo'

const ImpersonateLabel = ({ history }) => {
  const { t } = useTranslation()
  const [realHeaders] = useLocalStorage('user-impersonating-headers')
  const [realUser] = useLocalStorage('user-impersonating')
  const [userInfo] = useInfo()

  const handleCloseImpersonate = useCallback(() => {
    if (realUser && realHeaders) {
      // Set real user data and headers
      writeStorage('userInfo', realUser)
      writeStorage('token', realHeaders['token'])
      writeStorage('Authorization', realHeaders['Authorization'])
      writeStorage('client', realHeaders['client'])
      writeStorage('uid', realHeaders['uid'])

      // Removing recory items
      deleteFromStorage('user-impersonating-headers')
      deleteFromStorage('user-impersonating')
      deleteFromStorage('isImpersonating')

      if (realUser.data.role === 'admin') {
        history.push('/admin/users')
      } else {
        history.push('/coordinator/students')
      }

      window.location.reload()
    }
  }, [realUser, realHeaders])

  return (
    <ImpersonateContainer>
      <ActionContainer style={{ display: 'flex' }}>
        <ActionButtons
          buttonStyle={{
            backgroundColor: '#6F6F6F',
            color: '#30313c',
            transform: 'scale(0.6)',
          }}
          onCloseImpersonate={{
            action: handleCloseImpersonate,
          }}
        />
        <span>{t('offerings:backProfile')}</span>
      </ActionContainer>
      <ViewerContainer>
        <img alt='binocular icon' src={BinocularImage} />
        <span>
          {t('offerings:viewingHow')} <span style={{ color: '#c6c6c6' }}>{userInfo.data.name}</span>
        </span>
      </ViewerContainer>
    </ImpersonateContainer>
  )
}

export default withRouter(ImpersonateLabel)

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const ViewerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin: 0 5px;
  }
`
const ImpersonateContainer = styled.div`
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  background-color: #30313c;
  color: #929292;

  display: grid;
  justify-items: center;
  align-items: center;
  grid-template: 'action name x' auto / 30% 40% 30%;
  span {
    text-align: center;
    font-size: 14px;
  }
`
