import React from 'react'

import { Grid, Paper } from '@mui/material'
import { IMenuProps } from 'types/components/calendarDateRange'

import Month from './month'

const Menu: React.FC<IMenuProps> = ({
  dateRange,
  minDate,
  maxDate,
  currentDate,
  setDate,
  helpers,
  handlers,
  locale,
}) => {
  const commonProps = {
    dateRange,
    minDate,
    maxDate,
    helpers,
    handlers,
  }

  return (
    <Paper elevation={5} square>
      <Grid container direction='row' wrap='nowrap'>
        <Grid container direction='row' justifyContent='center' wrap='nowrap'>
          <Month {...commonProps} value={currentDate} setValue={setDate} navState={[true, true]} locale={locale} />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Menu
