import React from 'react'

import { notifyToast } from 'common/components/snackbar/Notify'

const listNotifyToast = (title, items, type = 'error', duration = 50000) =>
  notifyToast(
    <div>
      {title}
      <ul style={{ margin: 0, marginTop: 2, paddingLeft: 24 }}>
        {items?.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </div>,
    type,
    duration,
  )

export default listNotifyToast
