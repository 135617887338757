import React from 'react'
import { Editor, EditorProps, EditorState } from 'react-draft-wysiwyg'
import { Controller, useFormContext } from 'react-hook-form'

import { Box } from '@mui/material'
import { t } from 'i18next'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

interface IProps extends Partial<EditorProps> {
  name: string
  initialValue?: EditorState
}

export default function RichTextEditor({ name, initialValue, ...other }: IProps) {
  const { control, setValue, watch } = useFormContext(),
    currentEditorState = watch(name)

  const handleEditorStateChange = (newValue: EditorState) => {
    const hasContent = newValue.getCurrentContent().hasText() && newValue.getCurrentContent().getPlainText().length > 0,
      currentHasContent =
        currentEditorState.getCurrentContent().hasText() &&
        currentEditorState.getCurrentContent().getPlainText().length > 0

    if (hasContent || currentHasContent) {
      setValue(name, newValue, { shouldDirty: true })
    }
  }

  return (
    <Box
      sx={({ palette }) => ({
        border: `1px solid ${palette.divider}`,
        borderRadius: 2,
        minHeight: 30,
        '& .rdw-option-wrapper, .rdw-dropdown-wrapper': {
          border: 'none',
          '&:hover': {
            border: 'none',
            outline: `1px solid ${palette.divider}`, // prevent change on width
            boxShadow: 'none',
          },
        },
      })}
    >
      <Controller
        name={name}
        control={control}
        render={({ field: { value: currentEditorState } }) => (
          <Editor
            toolbarStyle={{
              border: 'none',
              background: 'unset',
            }}
            wrapperStyle={{
              border: 'none',
            }}
            editorStyle={{
              padding: '8px 16px',
              minHeight: 30,
            }}
            {...other}
            placeholder={t('login:type-here')}
            editorState={currentEditorState}
            onEditorStateChange={handleEditorStateChange}
            {...(initialValue && { defaultEditorState: initialValue })}
          />
        )}
        defaultValue={initialValue}
      />
    </Box>
  )
}
