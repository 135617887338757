import React from 'react'
import { withRouter } from 'react-router'

import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { List, ListItem, ListItemText, Collapse, Tooltip } from '@mui/material'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import styled from 'styled-components'

const NavbarLink = ({ history, type, text, path, links, tooltipText, isExternalPath }) => {
  const [anchor, setAnchor] = React.useState()
  const [subMenu, setSubMenu] = React.useState()

  const toggleSubMenu = (index) => {
    subMenu && subMenu === index ? setSubMenu(false) : setSubMenu(index)
  }

  const handleMenuClick = (link) => {
    setAnchor(null)
    toggleSubMenu(null)
    history.push(link)
  }

  return type === 'button' ? (
    <PureButton tooltipText={tooltipText} path={path} text={text} history={history} isExternalPath={isExternalPath} />
  ) : (
    <ExpandButton
      setAnchor={setAnchor}
      text={text}
      anchor={anchor}
      links={links}
      subMenu={subMenu}
      toggleSubMenu={toggleSubMenu}
      handleMenuClick={handleMenuClick}
    />
  )
}

const PureButton = ({ tooltipText, path, text, history, isExternalPath }) => {
  const handleChange = () => {
    isExternalPath ? (window.location.href = path) : history.push(path)
  }

  return tooltipText ? (
    <Tooltip title={tooltipText}>
      <CustomButton active={window.location.pathname === path ? 1 : 0} onClick={handleChange}>
        {text}
      </CustomButton>
    </Tooltip>
  ) : (
    <CustomButton active={window.location.pathname === path ? 1 : 0} onClick={handleChange}>
      {text}
    </CustomButton>
  )
}

const ExpandButton = ({ text, anchor, links, subMenu, setAnchor, toggleSubMenu, handleMenuClick }) => (
  <span>
    <CustomButton onClick={(e) => setAnchor(e.currentTarget)}>
      {text}
      {anchor ? <ExpandLess /> : <ExpandMore />}
    </CustomButton>

    <Menu anchorEl={anchor} open={Boolean(anchor)} onClose={() => setAnchor(null)}>
      {links &&
        links.map((area, index) => (
          <MenuItemComponent
            key={index}
            subMenu={subMenu}
            area={area}
            index={index}
            toggleSubMenu={toggleSubMenu}
            handleMenuClick={handleMenuClick}
          />
        ))}
    </Menu>
  </span>
)

const MenuItemComponent = ({ subMenu, area, toggleSubMenu, handleMenuClick }) => (
  <CustomMenuItem>
    <CustomList disablePadding>
      <ListItem button onClick={() => toggleSubMenu(area.name)}>
        <ListItemText inset primary={area.name} />
        {subMenu === area.name ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <CollapseComponent subMenu={subMenu} area={area} handleMenuClick={handleMenuClick} />
    </CustomList>
  </CustomMenuItem>
)

const CollapseComponent = ({ subMenu, area, handleMenuClick }) => (
  <Collapse in={subMenu === area.name} timeout='auto' unmountOnExit>
    <List component='div' disablePadding>
      {area.links &&
        area.links.map((link, index) => (
          <ListItem key={`${link.name}-${index}`} onClick={() => handleMenuClick(link.path)} button>
            {link.name}
          </ListItem>
        ))}
    </List>
  </Collapse>
)

export default withRouter(NavbarLink)

const CustomButton = styled(Button)`
  color: ${({ active }) => (active ? '#000000' : '#acacac')} !important;
`

const CustomMenuItem = styled(MenuItem)`
  height: auto !important;
  padding: 0 !important;
`

const CustomList = styled(List)`
  width: 100% !important;
`
