import React from 'react'

type UseLoadingState = [
  loading: boolean,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  result: any,
  setResult: React.Dispatch<React.SetStateAction<undefined>>,
]

export const useLoadingState = (): UseLoadingState => {
  const [result, setResult] = React.useState(undefined)
  const [loading, setLoading] = React.useState(false)
  return [loading, setLoading, result, setResult]
}
