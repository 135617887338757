import { CSSProperties } from 'react'

import styled from 'styled-components'

interface IFlex {
  justifyContent?: CSSProperties['justifyContent']
  alignItems?: CSSProperties['alignItems']
  flexDirection?: CSSProperties['flexDirection']
  flexWrap?: CSSProperties['flexWrap']
}

interface IRow {
  align?: CSSProperties['alignItems']
  margin?: CSSProperties['margin']
  justify?: CSSProperties['justifyContent']
}

interface ICol {
  flex?: CSSProperties['flex']
  fullWidth?: boolean
}

interface ISpace {
  size: number
}

const Flex = styled.div<IFlex>`
  display: flex;
  justify-content: ${({ justifyContent = 'inherit' }) => justifyContent};
  align-items: ${({ alignItems = 'inherit' }) => alignItems};
  flex-direction: ${({ flexDirection = 'inherit' }) => flexDirection};
  flex-wrap: ${({ flexWrap = 'inherit' }) => flexWrap};
`

export const Row = styled.div<IRow>`
  display: flex;
  align-items: ${({ align = 'unset' }) => align};
  margin: ${({ margin = 'unset' }) => margin};
  justify-content: ${({ justify = 'unset' }) => justify};
`

export const Col = styled(Row)<ICol>`
  flex-direction: column;
  flex: ${({ flex = 0 }) => flex};
  ${({ fullWidth = false }) => fullWidth && 'width: 100%;'}
`

export const Space = styled.div<ISpace>`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
`

export default Flex
