/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useState } from 'react'

import { AcademicEducation } from '../component/AcademicEducation'
import { InformationComplementary } from '../component/InformationComplementary'
import { InformationSchool } from '../component/InformationSchool'

export default function modalHooks() {
  const [stepPage, setStepPage] = useState(0)
  const [disableFields, setDisablefields] = useState<any>({
    graduation_status_yes: false,
    graduation_status_no: false,
    graduation_status_inprogress: false,
    specialization_status_yes: false,
    specialization_status_no: false,
    specialization_status_inprogress: false,
    masters_degree_status_yes: false,
    masters_degree_status_no: false,
    masters_degree_status_inprogress: false,
    degree_status_yes: false,
    degree_status_no: false,
    degree_status_inprogress: false,
    has_social_media_yes: false,
    has_social_media_no: false,
    general_building_conditions_good: false,
    general_building_conditions_rules: false,
    general_building_conditions_terrible: false,
    location_urban: false,
    location_rural: false,
    type_private: false,
    type_municipal: false,
    type_state: false,
    period_morning: false,
    period_evening: false,
    period_full: false,
    have_apm_yes: false,
    have_apm_inactive: false,
    have_apm_no: false,
    have_school_board_yes: false,
    have_school_board_inactive: false,
    have_school_board_no: false,
  })

  function Nextpage() {
    switch (stepPage) {
      case 0:
        return <AcademicEducation />
      case 1:
        return <InformationComplementary />
      case 2:
        return <InformationSchool />
      default:
        return null
    }
  }

  const fields = [
    ['specialization_course', 'degree_course', 'graduation_course', 'masters_degree_course', 'time_experience'],
    ['social_media', 'technical_features'],
    ['have_acessible_computer_lab', 'rooms'],
  ]

  function validateCheckBoxValue(value: string) {
    if (value?.includes('yes')) {
      return 'yes'
    } else if (value?.includes('no')) {
      return 'no'
    } else {
      return 'inprogress'
    }
  }
  function validateCheckBoxAcessibleValue(value: string) {
    if (value?.includes('good')) {
      return 'good'
    } else if (value?.includes('rules')) {
      return 'rules'
    } else {
      return 'terrible'
    }
  }
  function validateCheckBoxLocationValue(value: string) {
    if (value?.includes('urban')) {
      return 'urban'
    } else {
      return 'rural'
    }
  }
  function validateCheckBoxTypeValue(value: string) {
    if (value?.includes('private')) {
      return 'private'
    } else if (value?.includes('municipal')) {
      return 'municipal'
    } else {
      return 'state'
    }
  }
  function validateCheckBoxPeriodValue(value: string) {
    if (value?.includes('morning')) {
      return 'morning'
    } else if (value?.includes('evening')) {
      return 'evening'
    } else {
      return 'full'
    }
  }
  function validateCheckBoxHaveValue(value: string) {
    if (value?.includes('yes')) {
      return 'yes'
    } else if (value?.includes('inactive')) {
      return 'inactive'
    } else {
      return 'no'
    }
  }

  const disableField = useCallback(
    (fields: string[]) => {
      fields.forEach((item) => {
        setDisablefields((prev: any) => {
          return { ...prev, [item]: !disableFields[item] }
        })
      })
    },
    [disableFields],
  )

  return {
    validateCheckBoxValue,
    validateCheckBoxAcessibleValue,
    validateCheckBoxLocationValue,
    validateCheckBoxTypeValue,
    validateCheckBoxPeriodValue,
    validateCheckBoxHaveValue,
    Nextpage,
    setStepPage,
    stepPage,
    fields,
    disableFields,
    setDisablefields,
    disableField,
  }
}
