export const allowBloxStatusEdit = () =>
  new Promise((resolve) => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    resolve(
      userInfo &&
        userInfo.data.profiles.some((profile) =>
          profile.profile_permissions.find((permission) => permission.permission.key === 'update_blox_status'),
        ),
    )
  })
