import React from 'react'
import { toast } from 'react-toastify'

import { Alert, Fade, Snackbar } from '@mui/material'
import { INotifyProps, NotifyToastFunction } from 'types/components/snackbar'

const Transition = (props: any) => {
  return <Fade {...props} />
}

const Notify: React.FC<INotifyProps> = ({ variant, message, closeToast, duration }) => (
  <Snackbar
    open
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    autoHideDuration={duration}
    onClose={closeToast}
    TransitionComponent={Transition}
  >
    <Alert severity={variant}>{message} </Alert>
  </Snackbar>
)

export const notifyToast: NotifyToastFunction = (message, variant, duration) => {
  toast(<Notify variant={variant || 'success'} message={message} duration={duration || 3000} />)
}
