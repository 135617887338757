import React, { useState, useCallback, createContext, useContext, useMemo } from 'react'

import { useLocalStorage } from '@rehooks/local-storage'
import axios from 'axios'

import { buildUrl } from 'common/util/buildUrl'
import { roles } from 'common/util/roles'

const InternalImpersonationContext = createContext()

export const InternalImpersonationProvider = ({ children }) => {
  const [token] = useLocalStorage('token')
  const [Authorization] = useLocalStorage('Authorization')
  const [client] = useLocalStorage('client')
  const [uid] = useLocalStorage('uid')

  const [impersonationData, setImpersonationData] = useState({})

  const getImpersonationData = useCallback(async (userId) => {
    const { data: userInfo } = await axios({
      method: 'GET',
      url: buildUrl(`/v1/users/${userId}`),
      headers: {
        'access-token': token,
        Authorization,
        uid,
        client,
      },
    })

    const { data: userHeaders } = await axios({
      method: 'GET',
      url: buildUrl(`/v1/users/${userId}/simulate`),
      headers: {
        'access-token': token,
        Authorization,
        uid,
        client,
      },
    })

    return [userInfo, userHeaders]
  })

  const setImpersonation = useCallback(async (user) => {
    const [impersonationUserInfo, impersonationHeaders] = await getImpersonationData(user.id)

    setImpersonationData({
      impersonationUserInfo: {
        data: {
          ...impersonationUserInfo,
          role: roles(impersonationUserInfo.role),
          roles: impersonationUserInfo.role,
        },
      },
      impersonationHeaders,
    })
  })

  const resetImpersonation = useCallback(() => setImpersonationData({}))

  const hasImpersonation = useMemo(() => Object.keys(impersonationData).length > 0, [impersonationData])

  return (
    <InternalImpersonationContext.Provider
      value={{ hasImpersonation, ...impersonationData, setImpersonation, resetImpersonation }}
    >
      {children}
    </InternalImpersonationContext.Provider>
  )
}

const useInternalImpersonation = () => useContext(InternalImpersonationContext) || {}

export default useInternalImpersonation
