export const environment = () => {
  const hostname = window && window.location && window.location.hostname
  let env

  switch (hostname) {
    case 'plataformablox.com.br':
    case 'app.blox.education':
    case 'www.plataformablox.com.br':
      env = 'production'
      break
    case 'dev.blox.education':
      env = 'development'
      break
    case 'homolog.blox.education':
      env = 'homolog'
      break
    case 'staging.blox.education':
      env = 'staging'
      break
    default:
      break
  }

  return env
}
