import * as React from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import { TextField } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker'
import 'dayjs/locale/pt-br'

interface IDateTimePickerForm {
  name: string
  label: string
  defaultValue?: Date
  dateTimePickerProps?: Omit<DateTimePickerProps<any, any>, 'value' | 'onChange' | 'renderInput' | 'label'>
  textFieldProps?: Omit<React.ComponentProps<typeof TextField>, 'name' | 'variant' | 'error' | 'helperText'>
}

const Label = ({ content }: { content: string }) => <span>{content}</span>

export default function DateTimePickerForm({
  name,
  label,
  defaultValue,
  dateTimePickerProps,
  textFieldProps,
}: IDateTimePickerForm) {
  const { control } = useFormContext()

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <DateTimePicker
            label={<Label content={label} />}
            value={value}
            onChange={onChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                error={!!error}
                helperText={error ? error.message : ''}
                {...textFieldProps}
              />
            )}
            {...dateTimePickerProps}
          />
        )}
      />
    </LocalizationProvider>
  )
}
