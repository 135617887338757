import React from 'react'

import { useTheme } from '@mui/material'

export const Icon = () => {
  const {
    palette: { primary },
  } = useTheme()
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='41' height='41' viewBox='0 0 41 41'>
      <defs>
        <clipPath id='clip-path'>
          <path fill='#fff' stroke='#707070' strokeWidth='1' d='M0 0H41V41H0z' data-name='Retângulo 1765'></path>
        </clipPath>
      </defs>
      <g clipPath='url(#clip-path)' data-name='Grupo de máscara 8'>
        <g transform='translate(1.582)'>
          <path
            fill='#bed8fb'
            d='M11.122 25.6v9.979H3.283v-7.187a2.8 2.8 0 012.8-2.8h5.043z'
            data-name='Caminho 3086'
            transform='translate(-3.283 -13.258)'
          ></path>
          <path
            fill='#ddeafb'
            d='M45.632 15.727v21.957a3.287 3.287 0 01-3.287 3.288H15.338a3.288 3.288 0 01-3.2-2.521l-.013-1.88-.077-11.193V3.288A3.287 3.287 0 0115.338 0H29.9c1.309 0 5.549 3.615 9.3 7.422 3.42 3.475 6.43 7.11 6.43 8.3z'
            data-name='Caminho 3087'
            transform='translate(-7.825)'
          ></path>
          <path
            fill='#cbe2ff'
            d='M69.986 23.7v8.016c-.247-9.87-8.287-10.326-10.916-12.955l4.486-3.361c3.42 3.472 6.43 7.107 6.43 8.3z'
            data-name='Caminho 3088'
            transform='translate(-32.179 -7.976)'
          ></path>
          <path
            fill='#cbe2ff'
            d='M40.446 0h-8.015C42.3.248 42.757 8.288 45.386 10.916l3.365-4.486C45.276 3.011 41.641 0 40.446 0z'
            data-name='Caminho 3089'
            transform='translate(-18.366)'
          ></path>
          <path
            fill='#bed8fb'
            d='M63.322 15.727v1.391a5.3 5.3 0 00-5.3-5.3h-3.228a3.288 3.288 0 01-3.287-3.287V5.3A5.3 5.3 0 0046.2 0h1.391a9.443 9.443 0 016.677 2.765l6.285 6.285a9.443 9.443 0 012.765 6.677z'
            data-name='Caminho 3090'
            transform='translate(-25.515)'
          ></path>
          <path
            fill='#cbe2ff'
            d='M34.307 46.975v7.373a4.318 4.318 0 01-4.318 4.318H12.127l-.077-11.193v-4.816h17.938a4.318 4.318 0 014.319 4.318z'
            data-name='Caminho 3091'
            transform='translate(-7.824 -22.095)'
          ></path>
          <path
            fill={primary.light}
            d='M32.562 38.511v7.373a4.318 4.318 0 01-4.318 4.316H6.08a2.444 2.444 0 01-.246-.012 2.8 2.8 0 01-2.55-2.785V31.4a2.8 2.8 0 002.55 2.785 2.443 2.443 0 00.246.012h22.164a4.318 4.318 0 014.318 4.314z'
            data-name='Caminho 3092'
            transform='translate(-3.283 -16.263)'
          ></path>
          <path
            fill={primary.main}
            d='M5.834 34.182v16.01a2.8 2.8 0 01-2.55-2.785V31.4a2.8 2.8 0 002.55 2.785z'
            data-name='Caminho 3093'
            transform='translate(-3.283 -16.263)'
          ></path>
        </g>
      </g>
    </svg>
  )
}
