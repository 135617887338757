import React from 'react'
import { Container } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import styled from 'styled-components'

import { useInfo } from 'common/hooks/useInfo'

import NavbarLink from './links/NavbarLink'

const ProfessorNavbar = () => {
  const { t } = useTranslation()
  const [userInfo] = useInfo()
  const customLinks = userInfo.data.custom_links

  const professorLinks = (t) => [
    {
      text: t('dashboard:name'),
      path: '/professor',
    },
    ...(userInfo.data?.institution?.settings?.cometchat?.active
      ? [
          {
            text: t('dashboard:discussionGroups'),
            path: '/professor/discussion-groups',
          },
        ]
      : []),
  ]

  const bloxConfig = [
    {
      name: t('dashboard:curricularUnits'),
      links: [
        {
          name: t('dashboard:viewList'),
          path: '/professor/blox-list',
        },
        {
          name: t('dashboard:createNew'),
          path: '/coordinator/blox-creation',
        },
      ],
    },
  ]

  const links = React.useMemo(() => professorLinks(t), [userInfo])

  return (
    <CustomAppBar position='static'>
      <Toolbar>
        <Container>
          {links.map((link, index) => (
            <NavbarLink key={index} type='button' text={link.text} path={link.path} />
          ))}
          <NavbarLink text={t('dasboard:curricularUnits')} type='list' links={bloxConfig} />
          {customLinks &&
            customLinks.length > 0 &&
            customLinks.map((customLink) => (
              <NavbarLink
                type='button'
                key={customLink.id}
                text={customLink.title}
                path={customLink.url}
                tooltipText={customLink.description}
                isExternalPath={customLink.is_external}
              />
            ))}
        </Container>
      </Toolbar>
    </CustomAppBar>
  )
}

export default withRouter(ProfessorNavbar)

const CustomAppBar = styled(AppBar)`
  background-color: transparent !important;
  box-shadow: none !important;
`
