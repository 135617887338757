import React from 'react'

const TripleBlox = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='29.839' height='29.84' viewBox='0 0 29.839 29.84' {...props}>
    <g transform='translate(213 -19.822)'>
      <g transform='translate(-213 19.822)'>
        <path fill='#0090d0' d='M12.009,0l4,6.914-4,6.914H4L0,6.914,4,0Z' transform='translate(13.828) rotate(90)' />
        <path
          fill='#0090d0'
          d='M12.009,0l4,6.914-4,6.914H4L0,6.914,4,0Z'
          transform='translate(21.834 13.828) rotate(90)'
        />
        <path fill='#0090d0' d='M12.009,0l4,6.914-4,6.914H4L0,6.914,4,0Z' transform='translate(29.839) rotate(90)' />
      </g>
    </g>
  </svg>
)

export default TripleBlox
