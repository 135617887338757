import React, { useEffect, useState } from 'react'
import { Container } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import styled from 'styled-components'

import { useInfo } from 'common/hooks/useInfo'

import { allowBloxCreation } from 'routes/secure/routeguards/permissions/allowBloxCreation'
import { allowBloxEdit } from 'routes/secure/routeguards/permissions/allowBloxEdit'
import { allowBloxList } from 'routes/secure/routeguards/permissions/allowBloxList'
import { allowBloxStatusEdit } from 'routes/secure/routeguards/permissions/allowBloxStatusEdit'
import { allowDashboard } from 'routes/secure/routeguards/permissions/allowDashboard'
import { allowDiscussionGroups } from 'routes/secure/routeguards/permissions/allowDiscussionGroups'
import { allowOfferings } from 'routes/secure/routeguards/permissions/allowOfferings'
import { allowStudentImpersonate } from 'routes/secure/routeguards/permissions/allowStudentImpersonate'
import { allowStudentsList } from 'routes/secure/routeguards/permissions/allowStudentsList'

import NavbarLink from './links/NavbarLink'

const CoordinatorNavbar = () => {
  const { t } = useTranslation()
  const [userInfo] = useInfo()
  const [permissions, setPermissions] = useState([])
  const customLinks = userInfo.data && userInfo.data.custom_links

  const offeringConfig = [
    {
      name: t('dashboard:grid'),
      links: [
        {
          name: `${t('button:view')}`,
          path: '/coordinator/offerings',
        },
        {
          name: `${t('button:create')}`,
          path: '/coordinator/offerings/parameters',
        },
      ],
    },
  ]
  const bloxConfig = [
    {
      name: t('dashboard:curricularUnits'),
      links: [
        ...(permissions.includes('bloxList')
          ? [
              {
                name: t('dashboard:viewList'),
                path: '/coordinator/blox-list',
              },
            ]
          : []),
        ...(permissions.includes('bloxCreation')
          ? [
              {
                name: t('dashboard:createNew'),
                path: '/coordinator/blox-creation',
              },
            ]
          : []),
      ],
    },
  ]

  useEffect(() => {
    const call = async () => {
      const dashboard = await allowDashboard()
      const bloxCreation = await allowBloxCreation()
      const bloxEdit = await allowBloxEdit()
      const bloxList = await allowBloxList()
      const bloxStatusEdit = await allowBloxStatusEdit()
      const discussionGroups = await allowDiscussionGroups()
      const offerings = await allowOfferings()
      const studentImpersonate = await allowStudentImpersonate()
      const studentsList = await allowStudentsList()

      const final = [
        ...(dashboard ? ['dashboard'] : []),
        ...(bloxCreation ? ['bloxCreation'] : []),
        ...(bloxEdit ? ['bloxEdit'] : []),
        ...(bloxList ? ['bloxList'] : []),
        ...(bloxStatusEdit ? ['bloxStatusEdit'] : []),
        ...(discussionGroups ? ['discussionGroups'] : []),
        ...(offerings ? ['offerings'] : []),
        ...(studentImpersonate ? ['studentImpersonate'] : []),
        ...(studentsList ? ['studentsList'] : []),
      ]

      setPermissions(final)
    }
    call()
  }, [])

  return (
    <>
      <CustomAppBar position='static'>
        <Toolbar>
          <Container>
            <NavbarLink light type='button' text={t('dashboard:name')} path='/coordinator' />
            {permissions.includes('offerings') && (
              <NavbarLink light={false} type='list' text={t('offerings:name')} links={offeringConfig} />
            )}
            {!!bloxConfig[0]?.links?.length && (
              <NavbarLink light={false} text={t('dashboard:curricularUnits')} type='list' links={bloxConfig} />
            )}
            {permissions.includes('studentsList') && (
              <NavbarLink type='button' text={t('students:name')} path='/coordinator/students' />
            )}
            {customLinks &&
              customLinks.length > 0 &&
              customLinks.map((customLink) => (
                <NavbarLink
                  type='button'
                  key={customLink.id}
                  text={customLink.title}
                  path={customLink.url}
                  tooltipText={customLink.description}
                  isExternalPath={customLink.is_external}
                />
              ))}
          </Container>
        </Toolbar>
      </CustomAppBar>
    </>
  )
}

export default withRouter(CoordinatorNavbar)

const CustomAppBar = styled(AppBar)`
  background-color: transparent !important;
  box-shadow: none !important;
`
